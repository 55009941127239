import React, { useEffect, useState } from "react";
import { Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }

  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "locationAddress") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            {/* <div>{value}</div> */}
            <div className="ellipsis-cell">{value}</div>
          </Tooltip>
          <MapIcon latitude={record?.latitude} longitude={record?.longitude} />
        </div>
      );
    } else {
      return "NA";
    }
  }
  if (key === "updatedAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const Items: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Locations", href: "/locations" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const response = await get(apiEndpoints.getAllItems);
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleEdit = (record: any) => {
    navigate(`/items/editItem/${record.id}`);
    console.log(record, "record");
  };

  const handleDeleteConfirmation = async (id: string) => {
    try {
      if (id) {
        const response = await deleteMethod(
          `${apiEndpoints.deleteItemMaster}/${id}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await onSubmit();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.id);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) =>
        a.id
          .toString()
          .toLowerCase()
          .localeCompare(b.id.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "id"),
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) =>
        a.name
          .toString()
          .toLowerCase()
          .localeCompare(b.name.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Desc",
      dataIndex: "desc",
      key: "desc",
      sorter: (a: any, b: any) =>
        a.desc
          .toString()
          .toLowerCase()
          .localeCompare(b.desc.toString().toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "desc", record),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) =>
        a.price
          .toString()
          .toLowerCase()
          .localeCompare(b.price.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    // {
    //   title: "Contact Name",
    //   dataIndex: "contactName",
    //   key: "contactName",
    //   sorter: (a: any, b: any) =>
    //     a.contactName
    //       .toString()
    //       .toLowerCase()
    //       .localeCompare(b.contactName.toString().toLowerCase()),
    //   width: 100,
    //   render: renderStatus,
    // },
    // {
    //   title: "Contact Number",
    //   dataIndex: "contactNumber",
    //   key: "contactNumber",
    //   sorter: (a: any, b: any) =>
    //     a.contactNumber
    //       .toString()
    //       .toLowerCase()
    //       .localeCompare(b.contactNumber.toString().toLowerCase()),
    //   width: 100,
    //   render: renderStatus,
    // },
    // {
    //   title: "Contact Email",
    //   dataIndex: "contactEmail",
    //   key: "contactEmail",
    //   sorter: (a: any, b: any) =>
    //     a.contactEmail
    //       .toString()
    //       .toLowerCase()
    //       .localeCompare(b.contactEmail.toString().toLowerCase()),
    //   width: 100,
    //   render: renderStatus,
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    //   sorter: (a: any, b: any) =>
    //     a.updatedAt
    //       .toString()
    //       .toLowerCase()
    //       .localeCompare(b.updatedAt.toString().toLowerCase()),
    //   width: 100,
    //   render: (value) => renderStatus(value, "updatedAt"),
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 50,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/item/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default Items;
