// theme/themeConfig.ts
// import type { ThemeConfig } from "antd";

const Theme: any = {
  token: {
    fontSize: 16,
    colorPrimary: "#22ab53",
    colorSecondary: "#ef7729",
    colorThird: "#ffd107",
  },
};

export default Theme;
