import { atom } from "recoil";

// Define the interface for breadcrumb items
export interface BreadcrumbItem {
  label: string;
  href: string;
}

// Create a Recoil atom to manage the breadcrumb state
export const breadcrumbState = atom<BreadcrumbItem[]>({
  key: "breadcrumbState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (initial state)
});
