import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { companyState } from "../../../../lib/stores/companyId";
import { post } from "../../../../services/apiService";
import getApiEndpoints from "../../../../services/endpoints";
import CommonTable from "../../../../lib/components/Table/table";

const EventDetails = () => {
  const [eventsData, setEventsData] = useState([]);
  const { id } = useParams();
  const [companyId] = useRecoilState(companyState);
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData = JSON.parse(user || "{}"); // Added fallback for JSON.parse
  const userRole = userData?.roles?.[0]; // Added safe navigation
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    const handleData = async () => {
      try {
        const payLoad = {
          companyId: localStorage.getItem("companyId") || companyId,
          eventId: id,
        };
        const response = await post(
          apiEndpoints.getEventDetailsByEventId,
          payLoad
        );
        if (response.status === 200) {
          setEventsData(response?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleData();
  }, [companyId]); // Added apiEndpoints to dependency array

  const columns: any = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "left",
      sorter: (a: any, b: any) =>
        a.eventName
          .toString()
          .toLowerCase()
          .localeCompare(b.eventName.toString().toLowerCase()),
      width: 100,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a: any, b: any) =>
        a.companyName
          .toString()
          .toLowerCase()
          .localeCompare(b.companyName.toString().toLowerCase()),
      width: 100,
    },
    {
      title: "Event Start",
      dataIndex: "eventStartDate",
      key: "eventStartDate",
      align: "right",
      sorter: (a: any, b: any) =>
        a.eventStartDate
          .toString()
          .toLowerCase()
          .localeCompare(b.eventStartDate.toString().toLowerCase()),
      width: 100,
    },
    {
      title: "Event End",
      dataIndex: "eventEndDate",
      key: "eventEndDate",
      align: "right",
      sorter: (a: any, b: any) =>
        a.eventEndDate
          .toString()
          .toLowerCase()
          .localeCompare(b.eventEndDate.toString().toLowerCase()),
      width: 100,
    },
  ];

  return (
    <>
      <div>Event Details</div>
      {/* <Table columns={columns} dataSource={} /> */}
      <CommonTable
        routePath="/current-events/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={eventsData}
        columns={columns}
        initialValues={[]}
        // onFinish={onSubmit}
      />
    </>
  );
};

export default EventDetails;
