import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { useRecoilState } from "recoil";
import { companyState } from "../../../lib/stores/companyId";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import dayjs from "dayjs";
import { Card, Empty } from "antd";

const transformData = (data: any) => {
  return data.map((item: any) => ({
    storeID: item?.storeId,
    orderCount: item?.orderCount,
    glassesCount: item?.glassesCount,
    amount: (item?.amount / 1.12).toFixed(2),
  }));
};

const SalesSummary = () => {
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>([]);

  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const [filterData, setFilterData] = useState<any>([]);

  useEffect(() => {
    const dummyData: any = transformData(data);
    setFilterData(dummyData);
  }, [data]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchData = async () => {
      try {
        const response = await post(
          apiEndpoints.getSalesSummaryByCompanyId,
          payLoad
        );
        if (response.status === 200) {
          setData(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3>Sales Summary {dayjs().format("DD-MM-YYYY")}</h3>
        {data?.length > 0 ? (
          <BarChart
            width={600}
            height={500}
            data={filterData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="storeID" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              name="Order Count"
              dataKey="orderCount"
              fill="#0db53a"
              activeBar={<Rectangle fill="#0db53a" stroke="blue" />}
            />
            <Bar
              name="Glasses Count"
              dataKey="glassesCount"
              fill="#8884d8"
              activeBar={<Rectangle fill="#8884d8" stroke="blue" />}
            />
            <Bar
              name="Amount"
              dataKey="amount"
              fill="#faad07"
              activeBar={<Rectangle fill="#faad07" stroke="blue" />}
            />
          </BarChart>
        ) : (
          <div
            style={{
              margin: "50%",
              width: "200px",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default SalesSummary;
