import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../lib/stores/breadcrumb";
import OrderSummaryChart from "./Charts/orderSummary";
import OrderCountChart from "./Charts/orderCount";
import SalesSummary from "./Charts/salesSummary";
import RevenueSummaryPie from "./Charts/revenueSummaryPie";
import "./styles.css";
import { post } from "../../services/apiService";
import { companyState } from "../../lib/stores/companyId";
import getApiEndpoints from "../../services/endpoints";
import { CardContainer } from "./statisticsCards/cards";
import RevenueBar from "./Charts/revenueBar";
import TableComponent from "./Table/table";
import OrderGlassesSummary from "./Charts/ordersGlasses";

const Dashboard = () => {
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);

  useEffect(() => {
    setBreadCrumb([{ label: "Dashboard", href: "/" }]);
  }, [setBreadCrumb]);

  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>([]);

  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchData = async () => {
      try {
        const response = await post(
          apiEndpoints.getSalesSummaryTableByCompanyId,
          payLoad
        );
        if (response.status === 200) {
          setData(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    const fetchDashboard = async () => {
      try {
        const response = await post(
          apiEndpoints.getDashboardTopDataByCompany,
          payLoad
        );
        if (response.status === 200) {
          setDashboardData(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchDashboard();
    fetchData();
  }, []);

  return (
    <>
      <CardContainer data={dashboardData} />
      <TableComponent />
      <div className="chart-row" style={{ marginTop: "10px" }}>
        {/* <OrderSummaryChart />
        <OrderCountChart /> */}
        <OrderGlassesSummary />
        <RevenueBar />
        <SalesSummary />
        <RevenueSummaryPie />
      </div>
    </>
  );
};

export default Dashboard;
