import React, { useEffect, useState } from "react";
import { Button, Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }

  if (key === "createdAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const MobileDevices: React.FC = () => {
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Admin", href: "/dashboard" },
      { label: "Mobile Devices", href: "/mobile-devices" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const response = await get(apiEndpoints.getFCMMobileDevices);
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleSend = async (record: any) => {
    try {
      const payLoad = {
        message: "Testing message",
        ...record?.fcmDataID,
      };
      const response: any = await post(apiEndpoints.sendNotification, payLoad);
      if (response?.status === 200) {
        notification.success({
          message: "Notification Sent Successfully",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      sorter: (a: any, b: any) =>
        a.userName
          .toString()
          .toLowerCase()
          .localeCompare(b.userName.toString().toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "userName", record),
    },
    {
      title: "Device Model",
      dataIndex: "deviceModel",
      key: "deviceModel",
      sorter: (a: any, b: any) =>
        a.deviceModel
          .toString()
          .toLowerCase()
          .localeCompare(b.deviceModel.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        a.createdAt
          .toString()
          .toLowerCase()
          .localeCompare(b.createdAt.toString().toLowerCase()),
      width: 100,
      render: (value) => renderStatus(value, "createdAt"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 50,
      render: (record) => (
        <>
          <Button
            style={{ color: "#22ab53" }}
            onClick={() => handleSend(record)}
          >
            Send Notification
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default MobileDevices;
