import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
  maxLengthValidator,
  minLengthValidator,
  phoneNumberValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import "./page.css";

const LocationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.pathname, "location");
  const params = useParams();
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Location", href: "/locations" },
      {
        label: id ? "Edit Location" : "Add Location",
        href: id ? `/locations/${id}` : "/locations/add",
      },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await get(apiEndpoints.getAllCompanies);
        if (response && response.status === 200 && response.data) {
          setCompanyDropdown(response.data);
        }
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    };

    fetchOrg();
  }, []);

  const fetchById = async () => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      //   locId: params.id,
      // };
      // replace endpoint with location id endpoint to get details
      const response = await get(
        apiEndpoints.getLocationByLocationId + `/${id}`
        // payLoad
      );
      if (response.status === 200) {
        const initialValues = {
          ...response?.data[0],
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editLocation")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      const dropdownResponse = await get(apiEndpoints.getAllStates);
      if (dropdownResponse.status === 200) {
        setStatesDropdown(dropdownResponse?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values: any) => {
    try {
      const modifiedValues = {
        ...values,
        locId: id,
      };

      // Now use modifiedValues for submission
      console.log("Form Submit", values);
      // const payLoad: any = { ...values, companyId: companyId };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateLocationMaster",
        addDevice: "addLocationMaster",
      };
      const endPoint: any = id ? endPoints.updateDevice : endPoints.addDevice;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], modifiedValues);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/locations");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  const companyFormMeta: any = [
    {
      name: "companyId",
      label: "Company Name",
      type: "select",
      placeholder: "Select Company Name",
      rules: [{ required: true, message: "Select a Company Name!" }],
      widget: Select,
      onchange: (value: any) => {
        console.log(value, "value---");
      },
      widgetProps: {
        options: companyDropdown.map((c: any) => ({
          value: c.companyId,
          label: c.companyName,
        })),
      },
    },
    {
      name: "locationName",
      label: "Name",
      type: "input",
      placeholder: "Enter Location Name",
      rules: [
        { required: true, message: "This field is required" },
        { min: 4, message: "Name must be at least 4 characters" },
      ],
    },
    {
      name: "locationAddress",
      label: "Address",
      type: "input",
      placeholder: "Enter Company Address",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "stateCode",
      label: "State Name",
      type: "select",
      placeholder: "Select State Name",
      rules: [{ required: true, message: "Select a State Name!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: statesDropdown.map((c: any) => ({
          value: c.stateCode,
          label: c.stateName,
        })),
      },
    },
    {
      name: "contactName",
      label: "Contact Name",
      type: "input",
      // inputType: "number",
      placeholder: "Enter Contact Name",
    },
    // {
    //   name: "contactNumber",
    //   label: "Contact Number",
    //   type: "input",
    //   placeholder: "Enter Company Number",
    // },
    {
      name: "contactNumber",
      label: "Contact Number",
      // type: "input",
      // inputType: "number",
      type: "number",
      placeholder: "Enter Company Number",
      widgetProps: {
        maxLength: 10,
      },
      rules: [],
    },
    {
      name: "contactEmail",
      label: "Contact Email",
      type: "input",
      placeholder: "Enter Company Email",
      rules: [
        {
          validator: async (value: any) => {
            return emailValidator(value, false);
          },
        },
      ],
    },
    {
      name: "latitude",
      label: <div className="label-as">Latitude</div>,
      type: "input",
      placeholder: "Enter Latitude",
      rules: [],
    },
    {
      name: "longitude",
      label: <div className="label-as">Longitude</div>,
      type: "input",
      placeholder: "Enter Longitude",
      rules: [],
    },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="company-form"
        >
          {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
          <Row gutter={16}>
            {companyFormMeta.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update Location" : "Add Location"}
            </Button>
            <Button onClick={() => navigate("/locations")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default LocationForm;
