import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";

const CopyClipBoard = ({ text }: any) => {
  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Text copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Tooltip title={text}>
        <div>{text}</div>
      </Tooltip>
      <Button
        icon={<CopyOutlined />}
        onClick={() => copyTextToClipboard(text)}
      ></Button>
    </div>
  );
};

export default CopyClipBoard;
