import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import moment from "moment";
import "./page.css";

const ItemForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.pathname, "location");

  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDateData, setStartDateData] = useState<any>(null);
  const [endDateData, setEndDateData] = useState<any>(null);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Item", href: "/item" },
      {
        label: id ? "Edit Item" : "Add Item",
        href: id ? `/items/editItem/${id}` : "/item/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      // const payLoad = {
      //   companyId: id,
      // };
      // replace endpoint with location id endpoint to get details
      const response = await get(`${apiEndpoints.getItemMasterById}/${id}`);
      if (response.status === 200) {
        // const initialValues = {
        //   ...response?.data,
        //   active: response?.data?.isActive === 1 ? true : false,
        // };
        const int = response?.data["0"];
        form.setFieldsValue(int);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editItem")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      const dropdownResponse = await get(apiEndpoints.getAllStates);
      if (dropdownResponse.status === 200) {
        setStatesDropdown(dropdownResponse?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values: any) => {
    try {
      // Now use modifiedValues for submission
      console.log("Form Submit", values);
      // const payLoad: any = { ...values, companyId: companyId };
      const payLoad = {
        name: values.name,
        desc: values.desc,
        startDate: startDateData,
        endDate: endDateData,
        price: Number(values?.price),
      };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateItemMaster",
        addDevice: "createItemMaster",
      };
      const endPoint: any = id ? endPoints.updateDevice : endPoints.addDevice;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/item");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  const companyFormMeta: any = [
    {
      name: "name",
      label: "Item Name",
      type: "input",
      width: 100,
      placeholder: "Enter Item Name",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "desc",
      label: "Description",
      type: "input",
      placeholder: "Enter Description",
      rules: [{ required: true, message: "This field is required" }],
    },
    {
      name: "price",
      label: "Price",
      type: "input",
      inputType: "number",
      placeholder: "Enter Price",
    },
  ];

  const handleDateChange = (date, dateString) => {
    // Format the date to the desired format
    const formattedDate = date ? date.format("MM/DD/YYYY hh:mm A") : "";
    if (dateString === "startDate") {
      setStartDateData(formattedDate);
    } else {
      setEndDateData(formattedDate);
    }
    // Set the formatted date as the value of a form field
    form.setFieldsValue({ dateString: formattedDate });
  };

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="horizontal"
          className="company-form"
          labelCol={{ span: 8 }}
        >
          {/* <Row gutter={24}>
            {companyFormMeta.map((item: any, index: any) => (
              <Col span={8} key={index}>
                <FormBuilder meta={[item]} form={form} />
              </Col>
            ))}
          </Row> */}
          <Form.Item
            name="name"
            label="Item Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter Item Name" />
          </Form.Item>
          <Form.Item
            name="desc"
            label="Description"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter Desc" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "This field is required" }]}
          > 
            <Input placeholder="Enter Price" type="number" />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <DatePicker
              showTime
              format="MM/DD/YYYY hh:mm A"
              onChange={(e) => handleDateChange(e, "startDate")}
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <DatePicker
              showTime
              format="MM/DD/YYYY hh:mm A"
              onChange={(e) => handleDateChange(e, "endDate")}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update Item" : "Add Item"}
            </Button>
            <Button onClick={() => navigate("/item")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ItemForm;
