import React, { useEffect, useState } from "react";
import { Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
// import { EditOutlined } from "@ant-design/icons";
// import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
// import { useNavigate } from "react-router-dom";
// import { companyState } from "../../../lib/stores/companyId";
// import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import { companyState } from "../../../lib/stores/companyId";
import { Link, useParams } from "react-router-dom";
// import { companyState } from "../../../lib/stores/companyId";
// import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (
    key === "updatedAt" ||
    key === "eventStartDate" ||
    key === "eventEndDate"
  ) {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return (
        <Link
          to={`/event-details/${record.eventId}`}
          style={{ textDecoration: "none" }}
        >
          {date.toLocaleDateString()}
        </Link>
      );
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <Link
          to={`/event-details/${record.eventId}`}
          style={{ textDecoration: "none" }}
        >
          <div>{value}</div>
        </Link>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const CurrentEvents: React.FC = () => {
  // const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const { id } = useParams();
  console.log(id, "id");

  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Current Events", href: "/current-events" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
        isActive: location.pathname.includes("current-events") || false,
      };
      const response = await post(
        apiEndpoints.getAllActiveOrOldEventsByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  // const handleEdit = (record: any) => {
  //   navigate(`/users/editUsers/${record.userId}`);
  //   console.log(record, "record");
  // };

  // const handleDeleteConfirmation = async (userId: string) => {
  //   try {
  //     if (userId) {
  //       const response = await del(`${apiEndpoints.deleteSKUMaster}`, {
  //         companyId: localStorage.getItem("companyId") || companyId,
  //         userId: userId,
  //       });
  //       if (response.status === 200) {
  //         notification.success({
  //           message: "Successfully Deleted",
  //         });
  //         await onSubmit();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  // const handleDelete = (record: any) => {
  //   DiscardChangeModal(
  //     "Click OK to Confirm to Delete.",
  //     async () => {
  //       handleDeleteConfirmation(record?.userId);
  //     },
  //     () => {},
  //     { title: "Are you sure you want to Delete ?" }
  //   );
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      sorter: (a: any, b: any) =>
        a.eventName
          .toString()
          .toLowerCase()
          .localeCompare(b.eventName.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      // render: (value) => renderStatus(value, "eventName"),
      render: (value, record) => renderStatus(value, "eventName", record),
    },
    {
      title: "Event Start",
      dataIndex: "eventStartDate",
      key: "eventStartDate",
      sorter: (a: any, b: any) =>
        a.eventStartDate
          .toString()
          .toLowerCase()
          .localeCompare(b.eventStartDate.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value, record) => renderStatus(value, "eventStartDate", record),
    },
    {
      title: "Event End",
      dataIndex: "eventEndDate",
      key: "eventEndDate",
      sorter: (a: any, b: any) =>
        a.eventEndDate
          .toString()
          .toLowerCase()
          .localeCompare(b.eventEndDate.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value, record) => renderStatus(value, "eventEndDate", record),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a: any, b: any) =>
        a.companyName
          .toString()
          .toLowerCase()
          .localeCompare(b.companyName.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value, record) => renderStatus(value, "companyName", record),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        a.updatedAt
          .toString()
          .toLowerCase()
          .localeCompare(b.updatedAt.toString().toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "updatedAt", record),
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 50,
    //   render: (text, record) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Tooltip title="Edit">
    //         <EditOutlined onClick={() => handleEdit(record)} />{" "}
    //       </Tooltip>
    //       &nbsp; &nbsp;&nbsp;
    //       <Tooltip title="Delete">
    //         <DeleteOutlined onClick={() => handleDelete(record)} />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <CommonTable
        routePath="/current-events/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default CurrentEvents;
