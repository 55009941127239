import { Image } from "antd";

function MapIcon({ latitude, longitude }: any) {
  const openGoogleMaps = () => {
    if (latitude && longitude) {
      const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(url, "_blank");
    }
  };

  return (
    <Image
      preview={false}
      src={"/loc.webp"}
      onClick={openGoogleMaps}
      style={{ cursor: "pointer", width: "20px", height: "20px" }}
    />
  );
}

export default MapIcon;
