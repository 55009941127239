import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { companyState } from "../../../lib/stores/companyId";
import CommonTable from "../../../lib/components/Table/table";
import { columnsJson } from "./columnJson";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Activate the customParseFormat plugin to handle specific date formats
dayjs.extend(customParseFormat);
const today = dayjs().format("DD/MM/YYYY");

const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

  return formattedNumber;
};

const loadInitialValues = () => {
  const storedDates = sessionStorage.getItem("selectedDates");
  let formattedDates = [null, null];

  if (storedDates) {
    const dates = JSON.parse(storedDates);
    // Explicitly parse each date with the expected format
    formattedDates = [
      dayjs(dates[0], "DD/MM/YYYY").isValid()
        ? dayjs(dates[0], "DD/MM/YYYY")
        : null,
      dayjs(dates[1], "DD/MM/YYYY").isValid()
        ? dayjs(dates[1], "DD/MM/YYYY")
        : null,
    ];
  } else {
    formattedDates = [dayjs(today, "DD/MM/YYYY"), dayjs(today, "DD/MM/YYYY")];
  }

  const initialValues = {
    Machine: "All",
    Location: "All",
    dates: formattedDates,
  };

  return initialValues;
};

const PaymentReport: React.FC = () => {
  const columns = columnsJson;
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [machineDropdown, setMachines] = useState<any>([]);
  const [locationDropdown, setLocations] = useState<any>([]);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = loadInitialValues();

  useEffect(() => {
    setBreadCrumb([
      { label: "Transactions", href: "/" },
      { label: "Payment Reports", href: "/payment-reports" },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchDropdowns = async () => {
      const responseMachine = await post(
        apiEndpoints.getAllMachinesByCompanyId,
        payLoad
      );
      if (
        responseMachine &&
        responseMachine?.status === 200 &&
        responseMachine?.data
      ) {
        setMachines([...responseMachine.data]);
      }
      const responseLocation = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (
        responseLocation &&
        responseLocation?.status === 200 &&
        responseLocation?.data
      ) {
        setLocations([
          { locationName: "All", locationId: "All" },
          ...responseLocation.data,
        ]);
      }
    };
    fetchDropdowns();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
        companyId: localStorage.getItem("companyId") || companyId,
        machineId: values?.Machine === "All" ? null : values?.Machine,
        locationId: values?.Location === "All" ? null : values?.Location,
      };
      const response = await post(
        apiEndpoints.getPaymentReportsByAllParams,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const calculateTotals = (data: any) => {
    return data?.reduce(
      (acc, record) => {
        acc.amount += record?.amount;
        return acc;
      },
      {
        amount: 0,
      } // Initial totals
    );
  };

  const totals = calculateTotals(rowData);

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={true}
        pageName="Payment-Reports"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        // machines={machineDropdown}
        locations={locationDropdown}
        onFinish={onSubmit}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>Total Revenue: {formatNumber(totals?.amount / 1.12)}</b>
          </div>
        )}
      />
    </>
  );
};

export default PaymentReport;
