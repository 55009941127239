// Loader.js
import React from "react";
import { useRecoilValue } from "recoil";
import { isLoadingState } from "../store/loader";
import { Spin } from "antd";
// import { isLoadingState } from "./state";

const Loader = () => {
  const isLoading = useRecoilValue(isLoadingState);

  if (!isLoading) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: "1000",
        margin: "20% 80% 50% 49%",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default Loader;
