import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { useRecoilState } from "recoil";
import { companyState } from "../../../lib/stores/companyId";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import { Card, Empty } from "antd";

const RevenueBar = () => {
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>();

  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  // const fetchData = async () => {
  //   try {
  //     const payLoad = {
  //       companyId: localStorage.getItem("companyId") || companyId,
  //     };
  //     const response = await post(apiEndpoints.getRevenueByDay, payLoad);
  //     if (response.status === 200) {
  //       // setData(response?.data);
  //       const modifiedData = response?.data.map((item: any) => ({
  //         ...item,
  //         revenue: (item?.revenue / 1.12).toFixed(2),
  //       }));
  //       setData(modifiedData);
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchData = async () => {
      try {
        const response = await post(apiEndpoints.getRevenueByDay, payLoad);
        if (response.status === 200) {
          // setData(response?.data);
          const modifiedData = response?.data.map((item: any) => ({
            ...item,
            revenue: (item?.revenue / 1.12).toFixed(2),
          }));
          setData(modifiedData);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  const [max, setMax] = useState(null);

  useEffect(() => {
    // Assume fetchData sets `data` with the modified dataset
    // fetchData();

    if (data) {
      // Log the maximum revenue value to verify data integrity
      const maxRevenue = Math.max(
        ...data.map((item) => parseFloat(item.revenue))
      );
      setMax(maxRevenue);
      console.log("Maximum revenue value in dataset:", maxRevenue);
    }
  }, [data]); // Depend on `data` to re-run this effect when it changes

  const Num = Number(max + 1000);

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4>
          Revenue for 7 Days Cumulative total of all the locations under this
          Org
        </h4>
        {data?.length > 0 ? (
          <BarChart
            width={600}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="orderDate" />
            <YAxis dataKey="revenue" type="number" domain={[0, Num]} />
            <Tooltip />
            <Legend />
            <Bar
              name="Revenue"
              dataKey="revenue"
              fill="#faad07"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          </BarChart>
        ) : (
          <div
            style={{
              margin: "50%",
              width: "200px",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RevenueBar;
