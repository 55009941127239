import React, { useEffect, useState } from "react";
import { Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any) => {
  if (key === "createdAt" || key === "message" || key === "updatedAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div className="ellipsis-cell">{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const columns: ColumnsType<DataType> = [
  {
    title: "Topic Name",
    dataIndex: "topicName",
    key: "topicName",
    sorter: (a: any, b: any) =>
      a.topicName
        .toString()
        .toLowerCase()
        .localeCompare(b.topicName.toString().toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Device Address",
    dataIndex: "machineAddress",
    key: "machineAddress",
    sorter: (a: any, b: any) =>
      a.machineAddress
        .toString()
        .toLowerCase()
        .localeCompare(b.machineAddress.toString().toLowerCase()),
    width: 200,
    render: renderStatus,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    sorter: (a: any, b: any) =>
      a.message
        .toString()
        .toLowerCase()
        .localeCompare(b.message.toString().toLowerCase()),
    width: 200,
    render: (value) => renderStatus(value, "message"),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a: any, b: any) =>
      a.createdAt
        .toString()
        .toLowerCase()
        .localeCompare(b.createdAt.toString().toLowerCase()),
    width: 200,
    render: (value) => renderStatus(value, "createdAt"),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: (a: any, b: any) =>
      a.updatedAt
        .toString()
        .toLowerCase()
        .localeCompare(b.updatedAt.toString().toLowerCase()),
    width: 200,
    render: (value) => renderStatus(value, "updatedAt"),
  },
];

const AdminData: React.FC = () => {
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Admin", href: "/dashboard" },
      { label: "Admin Data", href: "/admin-data" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
      };
      const response = await post(apiEndpoints.getMessagesByDates, payLoad);
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={false}
        pageName="Admin-Data"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default AdminData;
