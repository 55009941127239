import * as yup from "yup";
import { each, isEmpty } from "lodash";

// export const AU_PHONE_REGEX =
//   /(^((\+61)|(0))){1}([1-9][0-9](?:[- ](?=\d{3})|(?![0-9][0-9][- ]?[0-9][- ]))\d\d[- ]?\d[- ]?\d{4})$/;
// export const EMAIL_REGEX =
//   // eslint-disable-next-line no-useless-escape
//   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const AU_PHONE_REGEX = /^[0-9]{10}$/; // Assuming you're targeting Australian phone numbers with exactly 10 digits

export function isValidRegex(value: string, regex: any) {
  return yup.string().matches(regex).isValid(value);
}

export async function emailValidator(email: string, required: boolean) {
  return new Promise<void>((resolve, reject) => {
    if ((email === undefined || email === null) && required) {
      reject(new Error("This field is required"));
    } else if (!EMAIL_REGEX.test(email) && required) {
      reject(new Error("Please enter a valid email"));
    } else {
      resolve();
    }
  });
}

export function postcodeValidator(value: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (value && `${value}`.length < 6) {
      return reject(new Error("The postcode field must be 6 digits"));
    }
    if (value && isNaN(value)) {
      return reject(new Error("Not a valid postal code."));
    }

    return resolve(value);
  });
}

export function requiredValidator(value: any, fieldName: string) {
  return new Promise((resolve, reject) => {
    if (!value || value === "") {
      return reject(new Error(`The ${fieldName} field is required`));
    } else return resolve(value);
  });
}

export async function phoneNumberValidator(
  phoneNumber: string | undefined | null
) {
  return new Promise<void>((resolve, reject) => {
    if (!phoneNumber && phoneNumber !== "") {
      reject(new Error("This field is required"));
    } else if (phoneNumber && !AU_PHONE_REGEX.test(phoneNumber)) {
      reject(new Error("Please enter a 10-digit mobile number"));
    } else {
      resolve();
    }
  });
}

export function prepareValidationMessage(
  message: string,
  replacements: any = {}
) {
  each(replacements, (value: string, key: string) => {
    const find = new RegExp("\\${" + key + "}");
    message = message.replace(find, value);
  });
  return message;
}

export function minLengthValidator(
  fieldName: string,
  minLength: number,
  value: string,
  trim: boolean = true
) {
  return new Promise((resolve, reject) => {
    if (value) {
      const formattedValue = trim ? value.trim() : value;
      if (formattedValue.length < minLength) {
        return reject(
          new Error(
            `${fieldName} must be at least ${minLength} characters long.`
          )
        );
      }
    }
    return resolve(value);
  });
}

export function maxLengthValidator(
  fieldName: string,
  maxLength: number,
  value: string,
  trim: boolean = true
) {
  return new Promise((resolve, reject) => {
    if (value) {
      const formattedValue = trim ? value?.trim() : value;
      if (formattedValue.length > maxLength) {
        return reject(
          new Error(
            `Your ${fieldName} must not exceed ${maxLength} characters.`
          )
        );
      }
    }
    return resolve(value);
  });
}

export function formValidator(form: any, ignoredFieldNames?: Array<string>) {
  return form.getFieldsError().some((item: any) => {
    if (
      !isEmpty(ignoredFieldNames) &&
      ignoredFieldNames?.includes(item.name[0])
    ) {
      return false;
    }

    return item.errors.length > 0;
  });
}

export function confirmPassworddValidator(password: any, confirmPassword: any) {
  return new Promise((resolve, reject) => {
    if (password !== confirmPassword) {
      return reject(
        new Error("The confirmed password is not matched. Please try again.")
      );
    } else return resolve(confirmPassword);
  });
}

const lonRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
const latRegex = /^([-+]?)([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

export function latitudeValidator(latitude: any) {
  return new Promise<void>((resolve, reject) => {
    // Convert latitude to string for regex test
    const latStr = String(latitude);

    if (latitude === undefined || latitude === null) {
      reject(new Error("Latitude is required"));
    } else if (!latRegex.test(latStr)) {
      reject(new Error("Invalid Latitude format"));
    } else {
      resolve();
    }
  });
}

export function longitudeValidator(longitude: any) {
  return new Promise<void>((resolve, reject) => {
    // Convert longitude to string for regex test
    const lonStr = String(longitude);

    if (longitude === undefined || longitude === null) {
      reject(new Error("Longitude is required"));
    } else if (!lonRegex.test(lonStr)) {
      reject(new Error("Invalid Longitude format"));
    } else {
      resolve();
    }
  });
}
