import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { companyState } from "../../../lib/stores/companyId";
import CommonTable from "../../../lib/components/Table/table";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CopyOutlined } from "@ant-design/icons";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

  return formattedNumber;
};

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getUTCDate());
};

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Text copied to clipboard!");
    },
    (err) => {
      console.error("Failed to copy text: ", err);
    }
  );
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "total" || key === "total") {
    const total = formatNumber(value); // Adjust decimal places as needed
    return `${total}`;
  }
  if (key === "revenue") {
    const total = formatNumber((record.total / 1.12).toFixed(2)); // Adjust decimal places as needed
    return `${total}`;
  }
  if (key === "gst") {
    const total = (record.total / 1.12).toFixed(2);
    const finalGST = Number(record.total) - Number(total);
    const GST = formatNumber(finalGST.toFixed(2)); // Adjust decimal places as needed
    return `${GST}`;
  }
  if (
    key === "startTime" ||
    key === "completedTime" ||
    key === "paymentTime"
  ) {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(
        date.getSeconds()
      )}`;
    } else {
      return "Invalid Date";
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div className="ellipsis-cell">{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const columns: ColumnsType<DataType> = [
  {
    title: "Order Date",
    width: 130,
    dataIndex: "orderDate",
    key: "orderDate",
    align: "center",
    sorter: (a: any, b: any) =>
      a.orderDate
        .toString()
        .toLowerCase()
        .localeCompare(b.orderDate.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Location",
    width: 220,
    dataIndex: "location",
    key: "location",
    align: "center",
    sorter: (a: any, b: any) =>
      a.storeId
        .toString()
        .toLowerCase()
        .localeCompare(b.storeId.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Transaction ID",
    width: 200,
    dataIndex: "transactionId",
    key: "transactionId",
    align: "center",
    sorter: (a: any, b: any) =>
      a.transactionId
        .toString()
        .toLowerCase()
        .localeCompare(b.transactionId.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Type",
    width: 200,
    dataIndex: "paymentType",
    key: "paymentType",
    align: "center",
    sorter: (a: any, b: any) =>
      a.transactionId
        .toString()
        .toLowerCase()
        .localeCompare(b.paymentType.toString().toLowerCase()),
    render: renderStatus,
  },
  
  // {
  //   title: "Ordered Glasses",
  //   width: 100,
  //   dataIndex: "glassesSelCount",
  //   key: "glassesSelCount",
  //   align: "center",
  //   sorter: (a: any, b: any) => a.glassesSelCount - b.glassesSelCount,
  //   render: renderStatus,
  // },
  {
    title: "Glass Count",
    width: 100,
    dataIndex: "glassCount",
    key: "glassCount",
    align: "center",
    sorter: (a: any, b: any) => a.glassesDispCount - b.glassesDispCount,
    render: renderStatus,
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    align: "right",
    width: 100,
    sorter: false,
    render: (value, record) => renderStatus(value, "revenue", record),
  },
  {
    title: "GST",
    dataIndex: "gst",
    key: "gst",
    align: "right",
    width: 100,
    sorter: false,
    render: (value, record) => renderStatus(value, "gst", record),
  },
  {
    title: "Total",
    width: 100,
    dataIndex: "total",
    key: "total",
    align: "right",
    sorter: (a: any, b: any) => a.total - b.total,
    render: (value, record) => renderStatus(value, "total", record),
  },
  {
    title: "Start Time",
    width: 150,
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    sorter: (a: any, b: any) =>
      a.transStartTime
        .toString()
        .toLowerCase()
        .localeCompare(b.transStartTime.toString().toLowerCase()),
    render: (value) => renderStatus(value, "startTime"),
  },
  {
    title: "Payment Time",
    width: 150,
    align: "center",
    dataIndex: "paymentTime",
    key: "paymentTime",
    sorter: (a: any, b: any) =>
      a.paymentTime
        .toString()
        .toLowerCase()
        .localeCompare(b.paymentTime.toString().toLowerCase()),
    render: (value) => renderStatus(value, "paymentTime"),
  },
  {
    title: "Completed Time",
    width: 150,
    align: "center",
    dataIndex: "completedTime",
    key: "completedTime",
    sorter: (a: any, b: any) =>
      a.completedTime
        .toString()
        .toLowerCase()
        .localeCompare(b.completedTime.toString().toLowerCase()),
    render: (value) => renderStatus(value, "completedTime"),
  },
  {
    title: "Machine Name",
    width: 150,
    dataIndex: "machineName",
    key: "machineName",
    align: "center",
    sorter: (a: any, b: any) =>
      a.machineName
        .toString()
        .toLowerCase()
        .localeCompare(b.machineName.toString().toLowerCase()),
    render: renderStatus,
  },
];

// Activate the customParseFormat plugin to handle specific date formats
dayjs.extend(customParseFormat);
const today = dayjs().format("DD/MM/YYYY");

const loadInitialValues = () => {
  const storedDates = sessionStorage.getItem("selectedDates");
  let formattedDates = [null, null];

  if (storedDates) {
    const dates = JSON.parse(storedDates);
    // Explicitly parse each date with the expected format
    formattedDates = [
      dayjs(dates[0], "DD/MM/YYYY").isValid()
        ? dayjs(dates[0], "DD/MM/YYYY")
        : null,
      dayjs(dates[1], "DD/MM/YYYY").isValid()
        ? dayjs(dates[1], "DD/MM/YYYY")
        : null,
    ];
  } else {
    formattedDates = [dayjs(today, "DD/MM/YYYY"), dayjs(today, "DD/MM/YYYY")];
  }

  const initialValues = {
    Machine: "All",
    Location: "All",
    dates: formattedDates,
  };

  return initialValues;
};

const OrderList: React.FC = () => {
  const initialValues = loadInitialValues();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [machineDropdown, setMachines] = useState<any>([]);
  const [locationDropdown, setLocations] = useState<any>([]);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    setBreadCrumb([
      { label: "Transactions", href: "/dashboard" },
      { label: "Order List", href: "/order-list" },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchDropdowns = async () => {
      const responseMachine = await post(
        apiEndpoints.getAllMachinesByCompanyId,
        payLoad
      );
      if (
        responseMachine &&
        responseMachine?.status === 200 &&
        responseMachine?.data
      ) {
        setMachines([
          { machineName: "All", machineId: "all" },
          ...responseMachine.data,
        ]);
      }
      const responseLocation = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (
        responseLocation &&
        responseLocation?.status === 200 &&
        responseLocation?.data
      ) {
        setLocations([
          { locationName: "All", locationId: "All" },
          ...responseLocation.data,
        ]);
      }
    };
    fetchDropdowns();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
        companyId: localStorage.getItem("companyId") || companyId,
        machineId: values?.Machine === "All" ? null : values?.Machine,
        locationId: values?.Location === "All" ? null : values?.Location,
      };
      const response = await post(
        apiEndpoints.getOrdersListByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const calculateTotals = (data: any) => {
    return data?.reduce(
      (acc, record) => {
        // Update totals for each column
        acc.total += record?.total;
        acc.revenue += parseFloat((record?.total / 1.12).toFixed(2)); // Example for revenue, adjust accordingly
        acc.gst += parseFloat(
          (
            Number(record?.total) -
            record?.total / 1.12
          ).toFixed(2)
        );
        acc.aurobindoRevenue += parseFloat(
          ((record?.total / 1.12) * 0.1).toFixed(2)
        );
        return acc;
      },
      {
        total: 0,
        revenue: 0,
        gst: 0,
        aurobindoRevenue: 0,
      } // Initial totals
    );
  };

  const totals = calculateTotals(rowData);

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={true}
        pageName="Order-List"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        // machines={machineDropdown}
        locations={locationDropdown}
        onFinish={onSubmit}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>Total Amount Received: {formatNumber(totals?.total)}</b>
            <b>Total Revenue: {formatNumber(totals?.revenue.toFixed(2))}</b>
            <b>Total GST: {formatNumber(totals?.gst.toFixed(2))}</b>
            {/* <b>Aurobindo Revenue: {formatNumber(totals?.aurobindoRevenue.toFixed(2))}</b> */}
          </div>
        )}
      />
    </>
  );
};

export default OrderList;
