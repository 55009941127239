import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./index.scss";
import App from "./App";
import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";
import Theme from "./theme/themeConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <ConfigProvider theme={Theme}>
      <App />
    </ConfigProvider>
  </RecoilRoot>
  // </React.StrictMode>
);
