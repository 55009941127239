import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  // message,
  Row,
  Col,
  Input,
  notification,
  message,
  DatePicker,
  TimePicker,
} from "antd";
// import { companyState } from "../../../lib/stores/companyId";
import "./page.css";
import { companyState } from "../../../lib/stores/companyId";

const dateFormat = "DD/MM/YYYY";
const format = "HH:mm";

const EventsForm = () => {
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [companyId] = useRecoilState(companyState);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);

  // const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // const { dropdownValue } = useContext(DropdownContext);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  // const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  // const userData = JSON.parse(user);
  // const userRole = userData?.roles[0];
  // const apiEndpoints = getApiEndpoints(userRole);
  const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);
  const [isEndTimeEnabled, setIsEndTimeEnabled] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Current Events", href: "/current-events" },
      {
        label: id ? "Edit Event" : "Add event",
        href: id ? `/current-events/${id}` : "/current-events/add",
      },
    ]);
  }, [setBreadCrumb]);

  // Function to handle changes in form values
  const onFormValuesChange = (_: any, allValues: any) => {
    // Enable or disable end date picker based on start date
    if (allValues.startDate) {
      setIsEndDateEnabled(true);
    } else {
      setIsEndDateEnabled(false);
    }

    // Enable or disable end time picker based on start time
    if (allValues.startTime) {
      setIsEndTimeEnabled(true);
    } else {
      setIsEndTimeEnabled(false);
    }
  };

  // Function to handle form submission
  const onFinish = async (values: any) => {
    // Define the time format
    try {
      const startHours = values.startTime.$H;
      const startMinutes = values.startTime.$m;
      const amOrPm = startHours >= 12 ? "PM" : "AM";
      const formattedstartHours = (startHours % 12 === 0 ? 12 : startHours % 12)
        .toString()
        .padStart(2, "0");
      const formattedMinutes = startMinutes.toString().padStart(2, "0");
      const startA = `${formattedstartHours}:${formattedMinutes} ${amOrPm}`;

      const endHours = values.endTime.$H;
      const endMinutes = values.endTime.$m;
      const amOrPmEnd = endHours >= 12 ? "PM" : "AM";
      const formattedEndHours = (endHours % 12 === 0 ? 12 : endHours % 12)
        .toString()
        .padStart(2, "0");
      const formattedMinutesEnd = endMinutes.toString().padStart(2, "0");
      const startB = `${formattedEndHours}:${formattedMinutesEnd} ${amOrPmEnd}`;
      const slotStartDate = values.startDate.format(dateFormat);
      const slotEndDate = values.endDate.format(dateFormat);
      // const startTime = values.startTime.format(format);
      // const endTime = values.endTime.format(format);

      if (endDate >= startDate) {
        const payLoad = {
          companyId: localStorage.getItem("companyId") || companyId,
          eventName: values.eventName,
          eventStartDate: `${slotStartDate} ${startA}`,
          eventEndDate: `${slotEndDate} ${startB}`,
        };
        const responsePricePlan = await post(
          apiEndpoints.addEventMaster,
          payLoad
        );
        if (responsePricePlan.status === 200) {
          notification.success({
            message: "Slot added successfully",
          });
          navigate("/current-events");
        }
      } else {
        notification.warning({
          message: "End date should be greater than or equal to start date",
        });
      }
    } catch (error) {
      console.error("Error saving slot:", error);
    }
  };

  const handleStartDateChange = (value: any) => {
    if (value) {
      setStartDate(value);
      if (endDate) {
        if (value <= endDate) {
          form.setFieldsValue({ startDate: value });
        } else {
          form.setFieldsValue({ startDate: null });
          notification.warning({
            message: "Start date should be less than or equal to end date",
          });
        }
      }
    } else {
      form.setFieldsValue({ endDate: null });
      notification.warning({
        message: "End date should be greater than or equal to start date",
      });
    }
  };
  const handleEndDateChange = (value: any) => {
    if (value >= startDate) {
      setEndDate(value);
      form.setFieldsValue({ endDate: value });
    } else {
      form.setFieldsValue({ endDate: null });
      notification.warning({
        message: "End date should be greater than or equal to start date",
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };
  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          onValuesChange={onFormValuesChange}
        >
          <Row gutter={24}>
            <Col xs={24} md={4}>
              <Form.Item
                label="Name"
                name="eventName"
                rules={[
                  { required: true, message: "Please select a Event Name" },
                  { min: 4, message: "Name must be at least 4 characters" },
                ]}
              >
                <Input placeholder="Enter Event Name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <DatePicker
                  // disabledDate={disabledDate}
                  format={dateFormat}
                  onChange={handleStartDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[{ required: true, message: "Start time is required" }]}
              >
                <TimePicker format={format} />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Date is required" }]}
              >
                {/* <DatePicker
                          disabledDate={disabledDate}
                          format={dateFormat}
                          disabled={!isEndDateEnabled}
                        /> */}
                <DatePicker
                  // disabledDate={disabledDate}
                  format={dateFormat}
                  disabled={!isEndDateEnabled}
                  onChange={handleEndDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[{ required: true, message: "End time is required" }]}
              >
                <TimePicker disabled={!isEndTimeEnabled} format={format} />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "10px" }}
          >
            Submit
          </Button>
          <Button onClick={() => navigate("/current-events")}>Cancel</Button>

          {/* </Row> */}
        </Form>
      </div>
    </>
  );
};

export default EventsForm;
