import React, { useEffect, useState } from "react";
import { Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import { differenceInMinutes, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }
  if (key === "health") {
    
    const backendTimestamp = record?.heartBeat; // Assuming title contains the backend timestamp
    const backendDate = parseISO(backendTimestamp);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const localDate = utcToZonedTime(backendDate, timeZone);
    const currentTime = new Date();
    const timeDifferenceInMinutes = differenceInMinutes(currentTime, localDate);
    // Determine whether it's a good time (less than 15 minutes)
    const isGoodTime = timeDifferenceInMinutes < 15;
    // 2 qr init
    const backendTimestampqrInit = record?.qrInit; // Assuming title contains the backend timestamp
    const backendDateqrInit = parseISO(backendTimestampqrInit);
    const trqrInit = Intl.DateTimeFormat().resolvedOptions();
    const localDateqrInit = utcToZonedTime(backendDateqrInit, trqrInit?.timeZone);
    const currentTimeqrInit = new Date();
    const timeDifferenceInMinutesqrInit = differenceInMinutes(currentTimeqrInit, localDateqrInit);
    // Determine whether it's a good time (less than 15 minutes)
    const isGoodTimeqrInit = timeDifferenceInMinutesqrInit < 15;
    // 3 paymentInit
    const backendTimestamppaymentInit = record?.paymentInit; // Assuming title contains the backend timestamp
    const backendDatepaymentInit = parseISO(backendTimestamppaymentInit);
    const paymentInit = Intl.DateTimeFormat().resolvedOptions();
    const localDatepaymentInit = utcToZonedTime(backendDatepaymentInit, paymentInit?.timeZone);
    const currentTimepaymentInit = new Date();
    const timeDifferenceInMinutespaymentInit = differenceInMinutes(currentTimepaymentInit, localDatepaymentInit);
    // Determine whether it's a good time (less than 15 minutes)
    const isGoodTimepaymentInit = timeDifferenceInMinutespaymentInit < 15;
    // 4
    const backendTimestamppaymentSuccess = record?.paymentSuccess; // Assuming title contains the backend timestamp
    const backendDatepaymentSuccess = parseISO(backendTimestamppaymentSuccess);
    const paymentSuccess = Intl.DateTimeFormat().resolvedOptions();
    const localDatepaymentSuccess = utcToZonedTime(backendDatepaymentSuccess, paymentSuccess?.timeZone);
    const currentTimepaymentSuccess = new Date();
    const timeDifferenceInMinutespaymentSuccess = differenceInMinutes(currentTimepaymentSuccess, localDatepaymentSuccess);
    // Determine whether it's a good time (less than 15 minutes)
    const isGoodTimepaymentSuccess = timeDifferenceInMinutespaymentSuccess < 15;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {record?.heartBeat !== null ? (
          <Tooltip title={"Heart Beat"}>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: isGoodTime ? "green" : "red",
                marginRight: "8px",
              }}
            />
          </Tooltip>) : "NA"
        }
        {record?.qrInit !== null && (
          <Tooltip title={"QR Init"}>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: isGoodTimeqrInit ? "green" : "red",
                marginRight: "8px",
              }}
            />
          </Tooltip>
        )}
        {record?.paymentInit !== null && (
          <Tooltip title={"Payment Init"}>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: isGoodTimepaymentInit ? "green" : "red",
                marginRight: "8px",
              }}
            />
          </Tooltip>
        )}{record?.paymentSuccess !== null && (
          <Tooltip title={"Payment Success"}>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: isGoodTimepaymentSuccess ? "green" : "red",
                marginRight: "8px",
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  }
  if (key === "locationAddress") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            {/* <div>{value}</div> */}
            <div className="ellipsis-cell">{value}</div>
          </Tooltip>
          <MapIcon latitude={record?.latitude} longitude={record?.longitude} />
        </div>
      );
    } else {
      return "NA";
    }
  }
  if (key === "updatedAt" || key === "createdAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      // return date.toLocaleDateString();
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}`;
    }
  }
  if (key === "machineMode") {
    if (value !== null) {
      return record?.machineMode === 0
        ? "Regular with Payment"
        : "Skip Payment";
      // {title === 0 ? "Off-Stop" : "Can Dispense"}
    }
  }
  if (key === "machineEventControl") {
    if (value !== null) {
      return record?.machineEventControl === 0 ? "Off-Stop" : "Can Dispense";
    }
  }
  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const Machines: React.FC = () => {
  // const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Machines", href: "/machines" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllMachinesByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  // const handleEdit = (record: any) => {
  //   navigate(`/machines/editMachines/${record.locId}`);
  //   console.log(record, "record");
  // };

  // const handleDeleteConfirmation = async (id: string) => {
  //   try {
  //     if (id) {
  //       const response = await del(`${apiEndpoints.deleteCompanyMaster}`, {
  //         companyId: id,
  //       });
  //       if (response.status === 200) {
  //         notification.success({
  //           message: "Successfully Deleted",
  //         });
  //         await onSubmit();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  // const handleDelete = (record: any) => {
  //   DiscardChangeModal(
  //     "Click OK to Confirm to Delete.",
  //     async () => {
  //       handleDeleteConfirmation(record?.companyId);
  //     },
  //     () => {},
  //     { title: "Are you sure you want to Delete ?" }
  //   );
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: "Health",
      dataIndex: "health",
      key: "health",
      width: 130,
      sorter: false,
      render: (value, record) => renderStatus(value, "health", record),
    },
    {
      title: "Address",
      dataIndex: "machineAddress",
      key: "machineAddress",
      width: 200,
      sorter: (a: any, b: any) =>
        a.machineAddress
          .toString()
          .toLowerCase()
          .localeCompare(b.machineAddress.toString().toLowerCase()),
      render: (value) => renderStatus(value, "machineAddress"),
    },
    {
      title: "Name",
      dataIndex: "machineName",
      key: "machineName",
      sorter: (a: any, b: any) =>
        a.machineName
          .toString()
          .toLowerCase()
          .localeCompare(b.machineName.toString().toLowerCase()),
      width: 120,
      render: renderStatus,
    },
    {
      title: "Type",
      dataIndex: "machineTypeName",
      key: "machineTypeName",
      sorter: (a: any, b: any) =>
        a.machineTypeName
          .toString()
          .toLowerCase()
          .localeCompare(b.machineTypeName.toString().toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "machineTypeName", record),
    },
    {
      title: "Loc Id",
      dataIndex: "locationId",
      key: "locationId",
      sorter: (a: any, b: any) =>
        a.locationId
          .toString()
          .toLowerCase()
          .localeCompare(b.locationId.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "locationId"),
    },
    {
      title: "Loc Name",
      dataIndex: "locationName",
      key: "locationName",
      sorter: (a: any, b: any) =>
        a.locationName
          .toString()
          .toLowerCase()
          .localeCompare(b.locationName.toString().toLowerCase()),
      width: 130,
      render: renderStatus,
    },
    {
      title: "State",
      dataIndex: "stateName",
      key: "stateName",
      sorter: (a: any, b: any) =>
        a.stateName
          .toString()
          .toLowerCase()
          .localeCompare(b.stateName.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Mode",
      dataIndex: "machineMode",
      key: "machineMode",
      sorter: (a: any, b: any) =>
        a.machineMode
          .toString()
          .toLowerCase()
          .localeCompare(b.machineMode.toString().toLowerCase()),
      width: 120,
      render: (record) => renderStatus(record, "machineMode"),
    },
    {
      title: "Control",
      dataIndex: "machineEventControl",
      key: "machineEventControl",
      sorter: (a: any, b: any) =>
        a.machineEventControl
          .toString()
          .toLowerCase()
          .localeCompare(b.machineEventControl.toString().toLowerCase()),
      width: 120,
      render: (record) => renderStatus(record, "machineEventControl"),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: (a: any, b: any) =>
        a.createdBy
          .toString()
          .toLowerCase()
          .localeCompare(b.createdBy.toString().toLowerCase()),
      width: 200,
      render: (value) => renderStatus(value, "createdBy"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        a.updatedAt
          .toString()
          .toLowerCase()
          .localeCompare(b.updatedAt.toString().toLowerCase()),
      width: 140,
      render: (value) => renderStatus(value, "updatedAt"),
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: (record) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Tooltip title="Edit">
    //         <EditOutlined
    //           onClick={() => handleEdit(record)}
    //           style={{ padding: 0, margin: 0 }}
    //         />
    //       </Tooltip>
    //       &nbsp; &nbsp;&nbsp;
    //       <Tooltip title="Delete">
    //         <DeleteOutlined
    //           onClick={() => handleDelete(record)}
    //           style={{ padding: 0, margin: 0 }}
    //         />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <CommonTable
        routePath={"/machines/add"}
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default Machines;
