import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { companyState } from "../../../lib/stores/companyId";
import CommonTable from "../../../lib/components/Table/table";
import { ColumnsType } from "antd/es/table";
import { CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend dayjs to understand custom formats
dayjs.extend(customParseFormat);

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

  return formattedNumber;
};
// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getUTCDate());
};

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Text copied to clipboard!");
    },
    (err) => {
      console.error("Failed to copy text: ", err);
    }
  );
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "total") {
    const total = formatNumber(Number(value)); // Adjust decimal places as needed
    return `${total}`;
  }
  // Handling the 'average' key
  if (key === "revenue") {
    const total = formatNumber((record.total / 1.12).toFixed(2)); // Adjust decimal places as needed
    return `${total}`;
  }
  if (key === "gst") {
    const total = (record.total / 1.12).toFixed(2);
    const finalGST = Number(record.total) - Number(total);
    const GST = formatNumber(finalGST.toFixed(2)); // Adjust decimal places as needed
    return `${GST}`;
  }
  if (key === "aurobindoRevenue") {
    // Assuming record.amount includes a 12% markup or tax, remove it to get the base amount
    const baseAmount = (record.amount / 1.12).toFixed(2); // Adjust decimal places as needed

    // Now, calculate 10% of the base amount for aurobindoRevenue
    const aurobindoRevenue = formatNumber(
      (Number(baseAmount) * 0.1).toFixed(2)
    ); // Adjust decimal places as needed

    return `${aurobindoRevenue}`;
  }

  if (key === "average") {
    // Calculate the average if both glassesCount and orderCount are available
    if (
      record.glassesCount != null &&
      record.orderCount != null &&
      record.orderCount !== 0
    ) {
      const average = (record.glassesCount / record.orderCount).toFixed(2); // Adjust decimal places as needed
      return `${average}`;
    } else {
      return "NA"; // Return 'NA' if data is not sufficient
    }
  } else if (key === "orderDate") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}`;
    }
  } else {
    // 'else' without condition
    if (value !== null) {
      return (
        <Tooltip title={value}>
          <div className="ellipsis-cell">{value}</div>
        </Tooltip>
      );
    } else {
      return "NA";
    }
  }
};

const columns: ColumnsType<DataType> = [
  {
    title: "Order Date",
    dataIndex: "orderDate",
    key: "orderDate",
    align: "center",
    width: 70,
    sorter: (a: any, b: any) =>
      a.orderDate
        .toString()
        .toLowerCase()
        .localeCompare(b.orderDate.toString().toLowerCase()),
    render: (value) => renderStatus(value, "orderDate"),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "storeId",
    align: "center",
    width: 130,
    sorter: (a: any, b: any) =>
      a.storeId
        .toString()
        .toLowerCase()
        .localeCompare(b.storeId.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Order Count",
    dataIndex: "orderCount",
    key: "orderCount",
    width: 75,
    align: "center",
    // sorter: (a: any, b: any) =>
    //   a.orderCount
    //     .toString()
    //     .toLowerCase()
    //     .localeCompare(b.orderCount.toString().toLowerCase()),
    sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    render: renderStatus,
  },
  {
    title: "Glass Count",
    dataIndex: "glassCount",
    key: "glassCount",
    width: 80,
    align: "center",
    sorter: (a: any, b: any) => a.glassesCount - b.glassesCount,
    render: renderStatus,
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    width: 70,
    key: "revenue",
    sorter: false,
    align: "right",
    // render: renderStatus,
    render: (value, record) => renderStatus(value, "revenue", record),
  },
  {
    title: "GST",
    dataIndex: "gst",
    key: "gst",
    width: 90,
    align: "right",
    sorter: false,
    render: (value, record) => renderStatus(value, "gst", record),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    width: 90,
    sorter: false,
    render: (value, record) => renderStatus(value, "total", record),
  },

  // {
  //   title: "Aurobindo Revenue",
  //   width: 200,
  //   dataIndex: "aurobindoRevenue",
  //   align: "right",

  //   key: "aurobindoRevenue",
  //   sorter: (a: any, b: any) =>
  //     a.amount
  //       .toString()
  //       .toLowerCase()
  //       .localeCompare(b.amount.toString().toLowerCase()),
  //   render: (value, record) => renderStatus(value, "aurobindoRevenue", record),
  // },
];

// Activate the customParseFormat plugin to handle specific date formats
dayjs.extend(customParseFormat);
const today = dayjs().format("DD/MM/YYYY");

const OrderSummary: React.FC = () => {
  const loadInitialValues = () => {
    const storedDates = sessionStorage.getItem("selectedDates");
    let formattedDates = [null, null];

    if (storedDates) {
      const dates = JSON.parse(storedDates);
      // Explicitly parse each date with the expected format
      formattedDates = [
        dayjs(dates[0], "DD/MM/YYYY").isValid()
          ? dayjs(dates[0], "DD/MM/YYYY")
          : null,
        dayjs(dates[1], "DD/MM/YYYY").isValid()
          ? dayjs(dates[1], "DD/MM/YYYY")
          : null,
      ];
    } else {
      formattedDates = [dayjs(today, "DD/MM/YYYY"), dayjs(today, "DD/MM/YYYY")];
    }

    const initialValues = {
      Machine: "All",
      Location: "All",
      dates: formattedDates,
    };

    return initialValues;
  };

  const initialValues = loadInitialValues();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [machineDropdown, setMachines] = useState<any>([]);
  const [locationDropdown, setLocations] = useState<any>([]);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    setBreadCrumb([
      { label: "Transactions", href: "/dashboard" },
      { label: "Order Summary", href: "/order-summary" },
    ]);
  }, [setBreadCrumb]);

  const fetchDropdowns = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const responseLocation = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (
        responseLocation &&
        responseLocation?.status === 200 &&
        responseLocation?.data
      ) {
        setLocations([
          { locationName: "All", locationId: "All" },
          ...responseLocation.data,
        ]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      console.log(values, "values");

      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
        companyId: localStorage.getItem("companyId") || companyId,
        machineId: values?.Machine === "All" ? null : values?.Machine,
        locationId: values?.Location === "All" ? null : values?.Location,
      };
      const response = await post(
        apiEndpoints.getOrdersSummaryByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const calculateTotals = (data: any) => {
    return data?.reduce(
      (acc, record) => {
        // Update totals for each column
        acc.orderCount += record?.orderCount;
        acc.glassCount += record?.glassCount;
        acc.revenue += parseFloat((record?.total / 1.12).toFixed(2)); // Example for revenue, adjust accordingly
        acc.gst += parseFloat(
          (Number(record?.total) - record?.total / 1.12).toFixed(2)
        ); // Example for GST
        acc.total += record?.total; // Assuming `amount` is the grand total
        acc.aurobindoRevenue += parseFloat(
          ((record?.total / 1.12) * 0.1).toFixed(2)
        ); // Example for Aurobindo Revenue
        return acc;
      },
      {
        orderCount: 0,
        glassCount: 0,
        revenue: 0,
        gst: 0,
        total: 0,
        aurobindoRevenue: 0,
      } // Initial totals
    );
  };

  const totals = calculateTotals(rowData);

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={true}
        pageName="Order-Summary"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        // machines={machineDropdown}
        locations={locationDropdown}
        onFinish={onSubmit}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>Total Order Count: {totals?.orderCount}</b>
            <b>Total Glasses Count: {totals?.glassCount}</b>
            <b>Total Revenue: {formatNumber(totals?.revenue.toFixed(2))}</b>
            <b>Total GST: {formatNumber(totals?.gst.toFixed(2))}</b>
            <b>Grand Total: {formatNumber(totals?.total.toFixed(2))}</b>
          </div>
        )}
      />
    </>
  );
};

export default OrderSummary;
