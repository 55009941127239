import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Tabs,
  Upload,
  message,
  Radio,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { get, post } from "../../../../services/apiService";
import getApiEndpoints from "../../../../services/endpoints";
import { useRecoilState } from "recoil";
import { companyState } from "../../../../lib/stores/companyId";
import { useNavigate, useParams } from "react-router-dom";
import { breadcrumbState } from "../../../../lib/stores/breadcrumb";
import "./page.css";

const { Option } = Select;
const { TabPane } = Tabs;

const CustomerFrom = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [statesDropdown, setStatesDropdown] = useState([]);
  const [parentCompanyDropdown, setParentCompanyDropdown] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  console.log(statesDropdown, "statesDropdown");
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>();
  console.log(data);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const { id } = useParams();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [parentId, setParentId] = useState<any>();

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Company", href: "/company" },
      {
        label: id ? "Edit Company" : "Add Company",
        href: id ? `/company/${id}` : "/company/add",
      },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    // const payLoad = {
    //   companyId: localStorage.getItem("companyId") || companyId,
    // };
    const fetchData = async () => {
      try {
        const response = await get(apiEndpoints.getListOfCompaniesUnderId);
        if (response.status === 200) {
          setParentCompanyDropdown(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchData();
    // fetchDd();
  }, []);

  //   useEffect(async () => {
  //     const dropdownResponse = await get(getApiEndpoints.getAllStates);
  //     if (dropdownResponse.status === 200) {
  //       setStatesDropdown(dropdownResponse?.data);
  //     }
  //   }, []);
  // Function to copy billing address to shipping address
  const handleCopyAddress = () => {
    form.setFieldsValue({
      "shippingAddress.country": form.getFieldValue("billingAddress.country"),
      "shippingAddress.detailAddress": form.getFieldValue(
        "billingAddress.detailAddress"
      ),
      "shippingAddress.city": form.getFieldValue("billingAddress.city"),
      "shippingAddress.state": form.getFieldValue("billingAddress.state"),
      "shippingAddress.zipcode": form.getFieldValue("billingAddress.zipcode"),
      "shippingAddress.phone": form.getFieldValue("billingAddress.phone"),
      "shippingAddress.fax": form.getFieldValue("billingAddress.fax"),
    });
  };

  // Handle form submission
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    try {
      const response = await post(apiEndpoints.addCompanyMaster, values);
      if (response.status === 200) {
        // setCompanyTypes(response?.data);
        navigate("/company");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleParent = async (e) => {
    setParentId(e);
    try {
      const payLoad = {
        companyId: e,
      };
      const response = await post(
        apiEndpoints.getCompanyTypesUnderCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setCompanyTypes(response?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    // Check if companyName is the field being changed
    if ("companyName" in changedValues) {
      // Update customerDisplayName with the same value
      form.setFieldsValue({
        customerDisplayName: changedValues.companyName,
      });
    }
  };

  return (
    <div>
      <Form
        onValuesChange={handleFormValuesChange}
        form={form}
        // layout="horizontal"
        onFinish={onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        layout="horizontal"
      >
        <Form.Item>
          <Input.Group
            compact
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "10px" }}>Parent Company</label>
              <Form.Item
                name={["companyMasterId"]}
                noStyle
                rules={[
                  { required: true, message: "parentCompany is required" },
                ]}
              >
                <Select
                  placeholder="Parent Company"
                  onSelect={handleParent}
                  style={{ width: "210px" }}
                >
                  {parentCompanyDropdown.map((item) => (
                    <Option key={item.companyId} value={item.companyId}>
                      {item.companyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>Company Type</label>
              <Form.Item
                name={["companyTypeId"]}
                noStyle
                rules={[
                  { required: true, message: "company Type is required" },
                ]}
              >
                <Select placeholder="Company Type" style={{ width: "210px" }}>
                  {companyTypes.map((item) => (
                    <Option key={item.companyTypeId} value={item.companyTypeId}>
                      {item.companyType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div style={{ margin: "0 10px" }}>
              <label>Business Type</label>
              <Form.Item name={["businessType"]}>
                <Radio.Group style={{ marginTop: "10px" }}>
                  <Radio value="Business"> Business </Radio>
                  <Radio value="Individual"> Individual </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <Input.Group
            compact
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ marginBottom: "10px" }}>Company Name</label>
              <Form.Item
                name={["companyName"]}
                noStyle
                rules={[{ required: true, message: "companyName is required" }]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>
                Customer Display Name
              </label>
              <Form.Item
                name={["customerDisplayName"]}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Customer Display Name is required",
                  },
                ]}
              >
                <Input placeholder="Customer Display Name" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0 10px",
              }}
            >
              <label style={{ marginBottom: "10px" }}>Company Email</label>
              <Form.Item name={["companyEmail"]}>
                <Input placeholder="Company Email" />
              </Form.Item>
            </div>
          </Input.Group>
        </Form.Item>
        <Form.Item style={{ marginLeft: "95px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ width: "15%", marginRight: "10px" }}>
              Primary Contact:
            </label>
            <Input.Group compact>
              <Form.Item
                name={["primaryContactSalutation"]}
                noStyle
                rules={[{ required: true, message: "Salutation is required" }]}
              >
                <Select
                  placeholder="Salutation"
                  style={{
                    width: "12%",
                    borderRadius: "6px",
                    marginRight: "20px",
                  }}
                >
                  <Option value="Mr">Mr.</Option>
                  <Option value="Mrs">Mrs.</Option>
                  <Option value="Ms">Ms.</Option>
                  <Option value="Miss">Miss</Option>
                  <Option value="Dr">Dr.</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={["primaryContactFirstName"]}
                noStyle
                rules={[{ required: true, message: "First name is required" }]}
              >
                <Input
                  placeholder="First Name"
                  style={{
                    width: "25%",
                    borderRadius: "6px",
                    marginRight: "20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["primaryContactLastName"]}
                noStyle
                rules={[{ required: true, message: "Last name is required" }]}
              >
                <Input
                  placeholder="Last Name"
                  style={{ width: "25%", borderRadius: "6px" }}
                />
              </Form.Item>
            </Input.Group>
          </div>
        </Form.Item>
        <Form.Item style={{ marginLeft: "95px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ width: "15%", marginRight: "10px" }}>
              Company Phone:
            </label>
            <Input.Group compact>
              <Form.Item
                name={["companyPhone"]}
                noStyle
                rules={[{ required: true, message: "Work Phone is required" }]}
              >
                <Input
                  placeholder="Work Phone"
                  style={{
                    width: "25%",
                    borderRadius: "6px",
                    marginRight: "10px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["companyMobile"]}
                noStyle
                rules={[{ required: true, message: "Mobile is required" }]}
              >
                <Input
                  placeholder="Mobile"
                  style={{
                    width: "25%",
                    margin: "0 10px",
                    borderRadius: "6px",
                  }}
                />
              </Form.Item>
            </Input.Group>
          </div>
        </Form.Item>
        <Form.Item
          name="active"
          valuePropName="checked"
          style={{ marginLeft: "95px" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ marginRight: "10px" }}>Status:</label>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="InActive"
              onChange={(checked) => {
                // Convert the boolean to '1' or '0' and set the form field value
                form.setFieldsValue({ active: checked ? 1 : 0 });
              }}
            />
          </div>
        </Form.Item>
        <Form.Item
          style={{ marginLeft: "95px" }}
          name="companyGST"
          rules={[{ required: true, message: "Company GST is required" }]}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ marginRight: "10px" }}>Company GST:</label>
            <Input
              placeholder="Company GST"
              style={{
                width: "20%",
                borderRadius: "6px",
              }}
            />
          </div>
        </Form.Item>
        {/* Tabs Section */}
        <Tabs defaultActiveKey="1">
          <TabPane tab="Other Details" key="1">
            {/* Other Details Form Items */}
            <Form.Item name="companyPAN" label="PAN">
              <Input placeholder="PAN Number" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Select placeholder="Customer Email" style={{ width: "20%" }}>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
                {/* Add other currencies as needed */}
              </Select>
            </Form.Item>
            <Form.Item name="openingBalance" label="Opening Balance">
              <Input
                prefix="₹"
                type="number"
                placeholder="Opening Balance"
                style={{ width: "20%" }}
              />
            </Form.Item>
            <Form.Item name="paymentTerms" label="Payment Terms">
              <Select placeholder="Payment Terms" style={{ width: "20%" }}>
                <Option value="net20">Net 20 Days</Option>
                <Option value="net40">Net 40 Days</Option>
                {/* Add other terms as needed */}
              </Select>
            </Form.Item>
            <Form.Item
              name="enablePortal"
              valuePropName="checked"
              label="Enable Portal"
            >
              <Checkbox>Allow Portal Access to this Customer</Checkbox>
            </Form.Item>
            <Form.Item name="portalLanguage" label="Portal Language">
              <Select placeholder="Portal Language" style={{ width: "20%" }}>
                <Option value="English">English</Option>
                <Option value="Spanish">Spanish</Option>
                {/* Add other languages as needed */}
              </Select>
            </Form.Item>
            {/* <Form.Item name="documents" label="Documents Upload">
              <Upload>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item> */}
          </TabPane>
          <TabPane tab="Address" key="2">
            {/* Billing Address */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flexBasis: "48%" }}>
                <h3>Billing Address</h3>
                <Form.Item name="billingAddress.country" label="Country/Region">
                  <Select placeholder="Country/Region" style={{ width: "40%" }}>
                    <Option value="India">India</Option>
                    <Option value="USA">USA</Option>
                    {/* Add other countries as needed */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="billingAddress.detailAddress"
                  label="Detail Address"
                >
                  <Input
                    placeholder="Detail Address"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="billingAddress.city" label="City">
                  <Input placeholder="City" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="billingAddress.state" label="State">
                  <Select placeholder="State" style={{ width: "40%" }}>
                    <Option value="Maharashtra">Maharashtra</Option>
                    <Option value="Texas">Texas</Option>
                    {/* Add other states as needed */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="billingAddress.zipcode"
                  label="Zipcode/Pincode"
                >
                  <Input
                    placeholder="Zipcode/Pincode"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="billingAddress.phone" label="Phone Number">
                  <Input placeholder="Phone Number" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="billingAddress.fax" label="Fax Number">
                  <Input placeholder="Fax Number" style={{ width: "40%" }} />
                </Form.Item>
              </div>
              {/* Shipping Address */}
              <div style={{ flexBasis: "48%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h3>Shipping Address &nbsp;</h3>(
                  <Button
                    type="link"
                    onClick={handleCopyAddress}
                    style={{ padding: 0 }}
                  >
                    Copy Billing Address to Shipping Address
                  </Button>
                  )
                </div>
                <Form.Item
                  name="shippingAddress.country"
                  label="Country/Region"
                >
                  <Select placeholder="Country/Region" style={{ width: "40%" }}>
                    <Option value="India">India</Option>
                    <Option value="USA">USA</Option>
                    {/* Add other countries as needed */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="shippingAddress.detailAddress"
                  label="Detail Address"
                >
                  <Input
                    placeholder="Detail Address"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="shippingAddress.city" label="City">
                  <Input placeholder="City" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="shippingAddress.state" label="State">
                  <Select placeholder="State" style={{ width: "40%" }}>
                    <Option value="Maharashtra">Maharashtra</Option>
                    <Option value="Texas">Texas</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="shippingAddress.zipcode"
                  label="Zipcode/Pincode"
                >
                  <Input
                    placeholder="Zipcode/Pincode"
                    style={{ width: "40%" }}
                  />
                </Form.Item>
                <Form.Item name="shippingAddress.phone" label="Phone Number">
                  <Input placeholder="Phone Number" style={{ width: "40%" }} />
                </Form.Item>
                <Form.Item name="shippingAddress.fax" label="Fax Number">
                  <Input placeholder="Fax Number" style={{ width: "40%" }} />
                </Form.Item>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Contact Persons" key="3">
            {/* Contact Persons Form Items */}
            <Form.Item label="Contact Person">
              <Input.Group compact>
                <Form.Item
                  name={["contactPerson", "salutation"]}
                  noStyle
                  rules={[
                    { required: true, message: "Salutation is required" },
                  ]}
                >
                  <Select placeholder="Salutation" style={{ width: "10%" }}>
                    <Option value="Mr">Mr.</Option>
                    <Option value="Mrs">Mrs.</Option>
                    <Option value="Ms">Ms.</Option>
                    <Option value="Miss">Miss</Option>
                    <Option value="Dr">Dr.</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "firstName"]}
                  noStyle
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    style={{
                      width: "20%",
                      margin: "0 10px",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "lastName"]}
                  noStyle
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input
                    placeholder="Last Name"
                    style={{ width: "20%", borderRadius: "6px" }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["contactPerson", "email"]}
              label="Email Address"
              rules={[{ required: true, type: "email" }]}
            >
              <Input placeholder="Email" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item name={["contactPerson", "phone"]} label="Phone">
              <Input placeholder="Customer Email" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item label="Customer Person">
              <Input.Group compact>
                <Form.Item
                  name={["contactPerson", "workPhone"]}
                  noStyle
                  rules={[
                    { required: true, message: "Work Phone is required" },
                  ]}
                >
                  <Input
                    placeholder="Work Phone"
                    style={{
                      width: "20%",

                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={["contactPerson", "mobile"]}
                  noStyle
                  rules={[{ required: true, message: "Mobile is required" }]}
                >
                  <Input
                    placeholder="Mobile"
                    style={{
                      width: "20%",
                      margin: "0 10px",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name={["contactPerson", "designation"]}
              label="Designation"
            >
              <Input placeholder="Designation" style={{ width: "20%" }} />
            </Form.Item>
            <Form.Item
              name={["contactPerson", "department"]}
              label="Department"
            >
              <Input placeholder="Department" style={{ width: "20%" }} />
            </Form.Item>
          </TabPane>
          <TabPane tab="Remarks" key="4">
            {/* Remarks */}
            <Form.Item name="remarks" label="Remarks">
              <Input.TextArea placeholder="Remarks" style={{ width: "40%" }} />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CustomerFrom;
