import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  AdminData,
  Company,
  CompanyForm,
  Dashboard,
  Login,
  MobileDevices,
  OrderFailure,
  OrderList,
  OrderRefunds,
  OrderSummary,
  PaymentReport,
  RefundData,
} from "./pages";
import AdminLayout from "./lib/templates/admin-layout";
import Locations from "./pages/Masters/Locations/page";
import LocationForm from "./pages/Masters/Locations/locationForm";
import Users from "./pages/Masters/Users/page";
import UsersForm from "./pages/Masters/Users/usersForm";
import CurrentEvents from "./pages/Masters/CurrentEvents/page";
import EventsForm from "./pages/Masters/CurrentEvents/eventsForm";
import EventDetails from "./pages/Masters/CurrentEvents/EventDetails/page";
import Machines from "./pages/Masters/Machines/page";
import MachinesForm from "./pages/Masters/Machines/machinesForm";
import MachineMap from "./pages/Masters/MachineMap/page";
import MachineMapForm from "./pages/Masters/MachineMap/machinemap";
import PricePlans from "./pages/Masters/PricePlans/page";
import PricePlanForm from "./pages/Masters/PricePlans/PriceplanForm";
import CompanyTypes from "./pages/Masters/CompanyTypes/page";
import SKU from "./pages/Masters/Sku/page";
import CustomerFrom from "./pages/Masters/Company/customer/customerForm";
import Items from "./pages/Masters/Items/page";
import ItemForm from "./pages/Masters/Items/itemForm";
import PrivateRoute from "./privateRoute/routeGuard";
import { useEffect } from "react";
import Loader from "./Loader/loader";
import FailureSummary from "./pages/Transactions/Failure-summary/page";
import PreviousEvents from "./pages/Masters/CurrentEvents copy/page";
import Forgot from "./pages/Forgot/forgot";
import Reset from "./pages/Reset/reset";
import UsersManageForm from "./pages/Masters/Users/usersManageForm";
import UsersRolesManage from "./pages/Masters/Users-role-manage/page";

const App = () => {
  return (
    <>
      <Loader />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset_password" element={<Reset />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/order-summary"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <OrderSummary />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/failure-summary"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <FailureSummary />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/order-list"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <OrderList />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/order-failure"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <OrderFailure />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/order-refund"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <OrderRefunds />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-reports"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <PaymentReport />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-data"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <AdminData />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/refund"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <RefundData />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/mobile-devices"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <MobileDevices />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/company"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Company />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/company/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  {/* <CompanyForm /> */}
                  <CustomerFrom />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/company/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <CompanyForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/company/editCompany/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <CompanyForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/locations"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Locations />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/locations/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <LocationForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/locations/editLocation/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <LocationForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/machines"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Machines />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/machines/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <MachinesForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/machines/editMachines/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <MachinesForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Users />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <UsersForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users/edit/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <UsersForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users/manage/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <UsersRolesManage />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/users/manage/add/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <UsersManageForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/current-events"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <CurrentEvents />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/current-events/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <EventsForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/event-details/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <EventDetails />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/machine-map"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <MachineMap />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/machine-map/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <MachineMapForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-machine-map/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <EventDetails />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/price-plans"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <PricePlans />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/price-plans/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <PricePlanForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/company-types"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <CompanyTypes />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/sku"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <SKU />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/item"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <Items />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/items/editItem/:id"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <ItemForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/item/add"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <ItemForm />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/previous-events"
            element={
              <PrivateRoute>
                <AdminLayout>
                  <PreviousEvents />
                </AdminLayout>
              </PrivateRoute>
            }
          />
          {/* </Route> */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
