import { Form, Input, Button, Checkbox, Card, Image, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../services/apiService";
import { companyState } from "../../lib/stores/companyId";
import { useRecoilState } from "recoil";
import "./reset.css";
import { isLoadingState } from "../../store/loader";

const Reset = () => {
  const navigate = useNavigate();
  const [, setCompanyId] = useRecoilState(companyState);
  const [loading, setLoading] = useRecoilState(isLoadingState);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");
  console.log(token, "token", email);

  const onFinish = async (values: { newPassword: string }) => {
    try {
      setLoading(true);

      if (values?.newPassword !== "") {
        const payLoad = {
          userName: email,
          token: token,
          newPassword: values?.newPassword,
        };
        const response = await post("/api/auth/resetPassword", payLoad);
        if (response) {
          navigate("/login");
          notification.success({
            message: response?.message,
          });
          setLoading(false);
        } else {
          console.log("reset failed:", response);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: " #def5b8",
      }}
    >
      <Card
        className="card-styles"
        style={{
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={"/Juice_logo.png"}
          alt="logo"
          // width={200}
          // height={100}
          preview={false}
        />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="newPassword"
            help={
              "Password must be at least 8 characters long, include a letter, a number, and a special character."
            }
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                pattern:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  "Password must be at least 8 characters long, include a letter, a number, and a special character.",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Enter your Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              // loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Reset;
