import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
  Switch,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  phoneNumberValidator,
} from "../../../lib/utils/form-validator";
// import { companyState } from "../../../lib/stores/companyId";
import "./page.css";
import { companyState } from "../../../lib/stores/companyId";

const UsersManageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.pathname, "location");

  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [locationDropdown, setLocationsDropdown] = useState([]);
  const [machineDropdown, setMachinesDropdown] = useState([]);
  const [data, setData] = useState<any>();
  const [roles, setRoles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Users", href: "/users" },
      { label: "Manage Roles", href: null },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      const payLoad = {
        companyId: id,
      };
      const response = await post(apiEndpoints.getCompanyMasterId, payLoad);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("editCompany")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const payLoad = {
          companyId: localStorage.getItem("companyId") || companyId,
        };
        const response = await post(
          apiEndpoints.getAllLocationsByCompany,
          payLoad
        );
        if (response.status === 200) {
          setLocationsDropdown(response?.data);
        }

        const responseFrom = await get(apiEndpoints.getUserManagerById + id);
        if (responseFrom.status === 200) {
          setData(responseFrom?.data[0]);
        }
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    };

    fetchOrg();
  }, []);

  const handleCompanyChange = async (val) => {
    console.log(val, "0000");
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const responseMachine = await get(
        apiEndpoints.getAllMachineInLocations + `?locationId=${val}`
      );
      if (responseMachine.status === 200) {
        setMachinesDropdown(responseMachine?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values: any) => {
    console.log(values, "values");

    try {
      // const userRoles: string[] = [];
      const modifiedValues = {
        ...values,
        companyId: companyId,
        userId: id,
      };

      // Now use modifiedValues for submission
      console.log("Form Submit", values);
      const payLoad: any = { ...modifiedValues };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateUserMapping",
        addDevice: "addUserMapping",
      };
      const endPoint: any = location.pathname.includes("add")
        ? endPoints.addDevice
        : endPoints.updateDevice;
      const method = location.pathname.includes("add") ? post : put;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.error("Please fill in all fields");
  };

  useEffect(() => {
    console.log(form, "form-");
  }, [form]);

  return (
    <>
      <label>Company Name: {data?.companyName}</label>

      <div
        className="filters"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        {/* <label>Company Name: </label> */}
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          className="company-form"
        >
          {/* <Row gutter={24}>
            <Col span={12}> */}
          <Form.Item
            label="Location"
            name="lstLocationId"
            rules={[{ required: true, message: "Please Select Location" }]}
          >
            <Select
              onChange={handleCompanyChange}
              mode="multiple"
              placeholder="Select Location"
              // style={{ width: "67%" }}
              options={locationDropdown.map((c: any) => ({
                value: c.locationId,
                label: c.locationName,
              }))}
            />
          </Form.Item>
          {/* </Col>
            <Col span={12}> */}
          <Form.Item
            label="Machine"
            name="lstMachineId"
            rules={[{ required: true, message: "Please Select Machine" }]}
          >
            <Select
              // onChange={handleCompanyChange}
              mode="multiple"
              placeholder="Select Machine"
              // style={{ width: "67%" }}
              options={machineDropdown.map((c: any) => ({
                value: c.machineId,
                label: c.machineName,
              }))}
            />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {location.pathname.includes("add")
                ? "Add User Roles"
                : "Update User Roles"}
            </Button>
            <Button onClick={() => navigate("/users")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default UsersManageForm;
