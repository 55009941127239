import { Table } from "antd";

function convertBackendTimestamp(value: any) {
  // Function to check if a string is an ISO 8601 date
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    return date.toLocaleDateString();
  }
}

const DataRefundTable = ({ data }: any) => {
  const dataSource = [
    {
      key: "transactionId",
      transactionId: data?.transactionId || "N/A",
      merchantId: data?.merchantId || "N/A",
      amount: data?.amount ? (data?.amount / 100).toFixed(2) : "N/A",
      deviceAddress: data?.deviceAddress || "N/A",
      storeId: data?.storeId || "N/A",
      terminalId: data?.terminalId || "N/A",
      paymentStatusSuccess: data?.paymentStatusSuccess ? "SUCCESS" : "FAILED",
      paymentStatusCode: data?.paymentStatusCode || "N/A",
      paymentStatusProviderReferenceId:
        data?.paymentStatusProviderReferenceId || "N/A",
      paymentStatusPaymentState: data?.paymentStatusPaymentState || "N/A",
      paymentStatusPayResponseCode: data?.paymentStatusPayResponseCode || "N/A",
      paymentStatusMessage: data?.paymentStatusMessage || "N/A",
      paymentStatusResponseDateTime:
        convertBackendTimestamp(data?.paymentStatusResponseDateTime) || "N/A",
      companyName: data?.companyName || "N/A",
    },
  ];

  const columns = [
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Merchant Id",
      dataIndex: "merchantId",
      key: "merchantId",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Device Address",
      dataIndex: "deviceAddress",
      key: "deviceAddress",
    },
    {
      title: "Store Id",
      dataIndex: "storeId",
      key: "storeId",
    },
    {
      title: "Terminal Id",
      dataIndex: "terminalId",
      key: "terminalId",
    },
    {
      title: "Payment Status Success",
      dataIndex: "paymentStatusSuccess",
      key: "paymentStatusSuccess",
    },
    {
      title: "Payment Status Code",
      dataIndex: "paymentStatusCode",
      key: "paymentStatusCode",
    },
    {
      title: "Payment Status Provider ReferenceId",
      dataIndex: "paymentStatusProviderReferenceId",
      key: "paymentStatusProviderReferenceId",
    },
    {
      title: "Payment Status Payment State",
      dataIndex: "paymentStatusPaymentState",
      key: "paymentStatusPaymentState",
    },
    {
      title: "Payment Status Pay Response Code",
      dataIndex: "paymentStatusPayResponseCode",
      key: "paymentStatusPayResponseCode",
    },
    {
      title: "Payment Status Message",
      dataIndex: "paymentStatusMessage",
      key: "paymentStatusMessage",
    },
    {
      title: "Payment Status Response DateTime",
      dataIndex: "paymentStatusResponseDateTime",
      key: "paymentStatusResponseDateTime",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
  ];

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export default DataRefundTable;
