import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Button, Popover } from "antd";
import "./page.css";
import { useEffect, useState } from "react";

function DownloadButtons(props: any) {
  // Function to format the current date as YYYY-MM-DD
  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to format camelCase keys to Title Case with spaces
  const formatKey = (key: string) => {
    return key
      .replace(/([A-Z])/g, " $1") // insert a space before all caps
      .replace(/^./, (str) => str.toUpperCase()) // uppercase the first character
      .trim();
  };

  // Function to format the keys of each object in the array
  const formatDataKeys = (data: any[]) => {
    return data.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        newItem[formatKey(key)] = item[key];
      });
      return newItem;
    });
  };

  const downloadCSV = () => {
    const formattedData = formatDataKeys(props.tableData); // Format the keys before unparse
    const csv = Papa.unparse(formattedData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${props.name}-${formatDate()}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadExcel = () => {
    const formattedData = formatDataKeys(props.tableData); // Format the keys before converting to sheet
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${props.name}-${formatDate()}.xlsx`);
  };

  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.innerWidth <= 768;
    }
    return false;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const exportOptions = (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Button
        onClick={downloadCSV}
        style={{
          marginRight: "10px",
          backgroundColor: "#ef7729",
          color: "#fff",
          border: "none",
        }}
      >
        CSV
      </Button>
      <Button
        onClick={downloadExcel}
        style={{ backgroundColor: "#ef7729", color: "#fff", border: "none" }}
      >
        Excel
      </Button>
    </div>
  );

  return (
    <div
      style={{
        right: !isMobile ? 0 : "",
      }}
    >
      <Popover content={exportOptions} trigger="hover">
        <Button type="primary">Export</Button>
      </Popover>
    </div>
  );
}

export default DownloadButtons;
