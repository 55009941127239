import React, { useEffect, useState } from "react";
import { Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import "./page.css";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const renderStatus = (value?: any, key?: any, record?: any) => {
  if (key === "userName") {
    if (record?.fcmDataID?.userName) {
      return (
        <Tooltip title={record.fcmDataID.userName}>
          <div>{record.fcmDataID.userName}</div>
        </Tooltip>
      );
    }
  }

  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "locationAddress") {
    if (value !== null) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={value}>
            {/* <div>{value}</div> */}
            <div className="ellipsis-cell">{value}</div>
          </Tooltip>
          <MapIcon latitude={record?.latitude} longitude={record?.longitude} />
        </div>
      );
    } else {
      return "NA";
    }
  }
  if (key === "updatedAt" || key === "createdAt") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      // return date.toLocaleDateString();
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}`;
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const MachineMap: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Machine Map", href: "/machine-map" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllMachineDeviceEventsList,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleEdit = (record: any) => {
    navigate(`/locations/editLocation/${record.locId}`);
    console.log(record, "record");
  };

  const handleDeleteConfirmation = async (id: string) => {
    try {
      if (id) {
        const response = await del(`${apiEndpoints.deleteCompanyMaster}`, {
          companyId: id,
        });
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await onSubmit();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.companyId);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      sorter: (a: any, b: any) =>
        a.eventName
          .toString()
          .toLowerCase()
          .localeCompare(b.eventName.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "eventName"),
    },
    {
      title: "Machine Name",
      dataIndex: "machineName",
      key: "machineName",
      sorter: (a: any, b: any) =>
        a.machineName
          .toString()
          .toLowerCase()
          .localeCompare(b.machineName.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Device Address",
      dataIndex: "deviceAddress",
      key: "deviceAddress",
      sorter: (a: any, b: any) =>
        a.deviceAddress
          .toString()
          .toLowerCase()
          .localeCompare(b.deviceAddress.toString().toLowerCase()),
      width: 100,
      // render: (value, record) => renderStatus(value, "deviceAddress", record),
    },
    {
      title: "Machine Status",
      dataIndex: "stateName",
      key: "stateName",
      sorter: (a: any, b: any) =>
        a.stateName
          .toString()
          .toLowerCase()
          .localeCompare(b.stateName.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        a.createdAt
          .toString()
          .toLowerCase()
          .localeCompare(b.createdAt.toString().toLowerCase()),
      width: 100,
      render: (value) => renderStatus(value, "createdAt"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        a.updatedAt
          .toString()
          .toLowerCase()
          .localeCompare(b.updatedAt.toString().toLowerCase()),
      width: 100,
      render: (value) => renderStatus(value, "updatedAt"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 50,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />{" "}
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/machine-map/add"
        showDates={false}
        showDropDowns={false}
        pageName="Machine-Map"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default MachineMap;
