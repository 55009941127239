import React, { useEffect, useState } from "react";
import { Empty } from "antd";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import { useRecoilState } from "recoil";
import { companyState } from "../../../lib/stores/companyId";
import "./table.css";

const TableComponent = () => {
  // const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>([]);

  // useEffect(() => {
  //   const payLoad = {
  //     companyId: localStorage.getItem("companyId") || companyId,
  //   };
  //   const fetchData = async () => {
  //     try {
  //       const response = await post(
  //         apiEndpoints.getSalesSummaryTableByCompanyId,
  //         payLoad
  //       );
  //       if (response.status === 200) {
  //         setData(response?.data);
  //       }
  //     } catch (error) {
  //       console.log(error, "error");
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    // Generate the last 7 days' dates
    const last7Days = [...Array(7)].map((_, i) => {
      const d = new Date();
      d.setDate(d.getDate() - i);
      return d.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
    }).reverse(); // Make sure dates are in ascending order

    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };

    const fetchData = async () => {
      try {
        const response = await post(
          apiEndpoints.getSalesSummaryTableByCompanyId,
          payLoad
        );
        if (response.status === 200) {
          // Map the response to include all last 7 days, filling missing dates with '0' data
          const enrichedData = response.data.map(store => {
            const enrichedSalesSummary = last7Days.map(date => {
              const existingData = store.salesSummaryObjList.find(item => item.orderDate === date);
              return existingData || { orderDate: date, orderCount: 0, glassesCount: 0, revenue: 0, gst: 0, total: 0 };
            });
            return { ...store, salesSummaryObjList: enrichedSalesSummary };
          });
          setData(enrichedData);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, [companyId]);
  const formatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number);

    return formattedNumber;
  };

  const normalFormatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(number);

    return formattedNumber;
  };

  const tableStyle: any = {
    borderCollapse: "collapse",
    border: "1px solid black",
  };
  const isMobile = window.innerWidth <= 767;

  const cellStyle: any = {
    border: "1px solid black",
    padding: "8px",
    fontSize: isMobile ? "4.5px" : "12px",
  };

  return (
    <>
      <div
        style={{
          background: "white",
          borderRadius: "10px",
          marginBottom: "15px",
        }}
      >
        <div>
          <h3 style={{ textAlign: "center", width: "100%" }}>
            Store wise Sales Summary (7 Days)
          </h3>
          <div>
            {data?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <table style={tableStyle}>
                  <thead style={{ background: "#fafafa" }}>
                    <tr>
                      <th
                        className="cellStyle"
                        style={{
                          fontSize: isMobile ? "4.5px" : "12px",
                          textAlign: "center",
                        }}
                      >
                        StoreId
                      </th>
                      <th
                        className="cellStyle"
                        style={{
                          fontSize: isMobile ? "4.5px" : "12px",
                          textAlign: "center",
                        }}
                      >
                        Desc
                      </th>
                      {data[0].salesSummaryObjList.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <th
                          key={index}
                          className="cellStyle"
                          style={{
                            fontSize: isMobile ? "4.5px" : "12px",
                            textAlign: "center",
                          }}
                        >
                          {item.orderDate}
                        </th>
                      ))}
                      <th
                        className="cellStyle"
                        style={{
                          fontSize: isMobile ? "4.5px" : "12px",
                          textAlign: "center",
                        }}
                      >
                        Totals
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((store) =>
                      store.salesSummaryObjList ? (
                        <React.Fragment key={store.storeID}>
                          <tr>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                              rowSpan={5}
                            >
                              {store.storeID}
                            </td>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              Order Count
                            </td>
                            {store.salesSummaryObjList.map((item) => (
                              <td
                                key={item.orderDate}
                                className="cellStyle"
                                style={{
                                  fontSize: isMobile ? "4.5px" : "12px",
                                  textAlign: "center",
                                }}
                              >
                                {normalFormatNumber(item.orderCount)}
                              </td>
                            ))}
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              {/* Calculate the total orderCount for the store */}
                              {/* {store.salesSummaryObjList.reduce(
                                (acc, item) => acc + item.orderCount,
                                0
                              )} */}
                              {normalFormatNumber(
                                store.salesSummaryObjList.reduce(
                                  (acc, item) => acc + item.orderCount,
                                  0
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              Glass Count
                            </td>
                            {store.salesSummaryObjList.map((item) => (
                              <td
                                key={item.orderDate}
                                className="cellStyle"
                                style={{
                                  fontSize: isMobile ? "4.5px" : "12px",
                                  textAlign: "center",
                                }}
                              >
                                {normalFormatNumber(item.glassesCount)}
                              </td>
                            ))}
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              {/* // Corrected Totals Calculation for Glasses Count */}
                              {normalFormatNumber(
                                store.salesSummaryObjList.reduce(
                                  (acc, item) => acc + item.glassesCount,
                                  0
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              Revenue
                            </td>
                            {store.salesSummaryObjList.map((item) => (
                              <td
                                key={item.orderDate}
                                className="cellStyle"
                                style={{
                                  fontSize: isMobile ? "4.5px" : "12px",
                                  textAlign: "right",
                                }}
                              >
                                {/* {formatNumber(item?.revenue)} */}
                                {formatNumber(Number(item?.revenue).toFixed(2))}
                              </td>
                            ))}
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "right",
                              }}
                            >
                              {/* // Corrected Revenue Totals Calculation */}
                              {formatNumber(
                                Number(
                                  store.salesSummaryObjList
                                    .reduce(
                                      (acc, item) => acc + item.revenue,
                                      0
                                    )
                                    .toFixed(2)
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              GST
                            </td>
                            {store.salesSummaryObjList.map((item) => (
                              <td
                                key={item.orderDate}
                                className="cellStyle"
                                style={{
                                  fontSize: isMobile ? "4.5px" : "12px",
                                  textAlign: "right",
                                }}
                              >
                                {formatNumber(Number(item?.gst).toFixed(2))}
                              </td>
                            ))}
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "right",
                              }}
                            >
                              {/* // Corrected GST Totals Calculation */}
                              {formatNumber(
                                Number(
                                  store.salesSummaryObjList
                                    .reduce((acc, item) => acc + item.gst, 0)
                                    .toFixed(2)
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </td>
                            {store.salesSummaryObjList.map((item) => (
                              <td
                                key={item.orderDate}
                                className="cellStyle"
                                style={{
                                  fontSize: isMobile ? "4.5px" : "12px",
                                  textAlign: "right",
                                }}
                              >
                                {formatNumber(Number(item?.total).toFixed(2))}
                              </td>
                            ))}
                            <td
                              className="cellStyle"
                              style={{
                                fontSize: isMobile ? "4.5px" : "12px",
                                textAlign: "right",
                              }}
                            >
                              {/* // Corrected Total Amount Calculation */}
                              {formatNumber(
                                Number(
                                  store.salesSummaryObjList
                                    .reduce((acc, item) => acc + item.total, 0)
                                    .toFixed(2)
                                )
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : null
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              // <div style={{ background: "#fff", height: "5vh" }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              // </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TableComponent;
