import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { ColumnsType } from "antd/es/table";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  align: "center";
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      message.success("Text copied to clipboard!");
    },
    (err) => {
      console.error("Failed to copy text: ", err);
    }
  );
};

const formatNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(number);

  return formattedNumber;
};

// Custom render function for various types of values
const renderStatus = (value?: any, key?: any, record?: any) => {
  if (
    key === "updated_at" ||
    key === "qrinit_pay_response_datetime" ||
    key === "update_datetime" ||
    key === "paymentstatus_response_datetime"
  ) {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      // return date.toLocaleDateString();
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(
        date.getSeconds()
      )}`;
    }
  }
  // Handling boolean values
  if (value === true) {
    return <CheckCircleOutlined style={{ color: "green" }} />;
  } else if (value === false) {
    return <CloseCircleOutlined style={{ color: "red" }} />;
  }
  if (key === "revenue") {
    const amount = formatNumber((record.amount / 1.12).toFixed(2)); // Adjust decimal places as needed
    return `${amount}`;
  }
  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div className="ellipsis-cell">{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return value === 0 ? "0" : "NA";
};

export const columnsJson: ColumnsType<DataType> = [
  {
    title: "Transaction Id",
    width: 200,
    dataIndex: "transaction_id",
    align: "center",
    key: "transaction_id",
    sorter: (a: any, b: any) =>
      a.transaction_id
        .toString()
        .toLowerCase()
        .localeCompare(b.transaction_id.toString().toLowerCase()),
    // render: renderStatus,
    render: renderStatus,
  },
  {
    title: "Payment Type",
    width: 170,
    dataIndex: "payment_type",
    align: "center",
    key: "payment_type",
    sorter: (a: any, b: any) =>
      a.payment_type
        .toString()
        .toLowerCase()
        .localeCompare(b.payment_type.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Revenue",
    width: 100,
    dataIndex: "amount",
    align: "right",
    key: "amount",
    sorter: false,
    // render: renderStatus,
    render: (value, record) => renderStatus(value, "revenue", record),
  },
  {
    title: "Device Address",
    width: 170,
    dataIndex: "device_address",
    align: "center",
    key: "device_address",
    sorter: (a: any, b: any) =>
      a.device_address
        .toString()
        .toLowerCase()
        .localeCompare(b.device_address.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Store Id",
    width: 120,
    dataIndex: "store_id",
    align: "center",
    key: "store_id",
    sorter: (a: any, b: any) =>
      a.store_id
        .toString()
        .toLowerCase()
        .localeCompare(b.store_id.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Terminal Id",
    width: 120,
    dataIndex: "terminal_id",
    align: "center",
    key: "terminal_id",
    sorter: (a: any, b: any) =>
      a.terminal_id
        .toString()
        .toLowerCase()
        .localeCompare(b.terminal_id.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Qr Init Code",
    width: 120,
    dataIndex: "qrinit_code",
    align: "center",
    key: "qrinit_code",
    sorter: (a: any, b: any) =>
      a.qrinit_code
        .toString()
        .toLowerCase()
        .localeCompare(b.qrinit_code.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Qr Init Success",
    width: 120,
    dataIndex: "qrinit_success",
    align: "center",
    key: "qrinit_success",
    sorter: (a: any, b: any) =>
      a.qrinit_success
        .toString()
        .toLowerCase()
        .localeCompare(b.qrinit_success.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Status Code",
    width: 200,
    dataIndex: "paymentstatus_code",
    align: "center",
    key: "paymentstatus_code",
    sorter: (a: any, b: any) =>
      a.paymentstatus_code
        .toString()
        .toLowerCase()
        .localeCompare(b.paymentstatus_code.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Status Provider ReferenceId",
    width: 240,
    dataIndex: "paymentstatus_provider_referenceid",
    align: "center",
    key: "paymentstatus_provider_referenceid",
    sorter: (a: any, b: any) =>
      a.paymentstatus_provider_referenceid
        .toString()
        .toLowerCase()
        .localeCompare(
          b.paymentstatus_provider_referenceid.toString().toLowerCase()
        ),
    render: renderStatus,
  },
  {
    title: "Payment Status Payment State",
    width: 180,
    dataIndex: "paymentstatus_payment_state",
    align: "center",
    key: "paymentstatus_payment_state",
    sorter: (a: any, b: any) =>
      a.paymentstatus_payment_state
        .toString()
        .toLowerCase()
        .localeCompare(b.paymentstatus_payment_state.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Status Pay Response Code",
    width: 200,
    dataIndex: "paymentstatus_pay_response_code",
    align: "center",
    key: "paymentstatus_pay_response_code",
    sorter: (a: any, b: any) =>
      a.paymentstatus_pay_response_code
        .toString()
        .toLowerCase()
        .localeCompare(
          b.paymentstatus_pay_response_code.toString().toLowerCase()
        ),
    render: renderStatus,
  },
  {
    title: "Payment Status Response DateTime",
    width: 250,
    dataIndex: "paymentstatus_response_datetime",
    align: "center",
    key: "paymentstatus_response_datetime",
    sorter: (a: any, b: any) =>
      a.paymentstatus_response_datetime
        .toString()
        .toLowerCase()
        .localeCompare(
          b.paymentstatus_response_datetime.toString().toLowerCase()
        ),
    render: (value) => renderStatus(value, "paymentstatus_response_datetime"),
  },
  {
    title: "Machine Name",
    width: 150,
    dataIndex: "machineName",
    align: "center",
    key: "machineName",
    sorter: (a: any, b: any) =>
      a.machineName
        .toString()
        .toLowerCase()
        .localeCompare(b.machineName.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Loc Name",
    width: 150,
    dataIndex: "locName",
    align: "center",
    key: "locName",
    sorter: (a: any, b: any) =>
      a.locName
        .toString()
        .toLowerCase()
        .localeCompare(b.locName.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Provider",
    width: 150,
    dataIndex: "payment_provider",
    align: "center",
    key: "payment_provider",
    sorter: (a: any, b: any) =>
      a.payment_provider
        .toString()
        .toLowerCase()
        .localeCompare(b.payment_provider.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Payment Status Message",
    width: 250,
    dataIndex: "paymentstatus_message",
    align: "center",
    key: "paymentstatus_message",
    sorter: (a: any, b: any) =>
      a.paymentstatus_message
        .toString()
        .toLowerCase()
        .localeCompare(b.paymentstatus_message.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Qr Init Message",
    width: 370,
    dataIndex: "qrinit_message",
    align: "center",
    key: "qrinit_message",
    sorter: (a: any, b: any) =>
      a.qrinit_message
        .toString()
        .toLowerCase()
        .localeCompare(b.qrinit_message.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Qr String",
    width: 200,
    dataIndex: "qr_string",
    align: "center",
    key: "qr_string",
    sorter: (a: any, b: any) =>
      a.qr_string
        .toString()
        .toLowerCase()
        .localeCompare(b.qr_string.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Qr Init Pay Response DateTime",
    width: 200,
    dataIndex: "qrinit_pay_response_datetime",
    align: "center",
    key: "qrinit_pay_response_datetime",
    sorter: (a: any, b: any) =>
      a.qrinit_pay_response_datetime
        .toString()
        .toLowerCase()
        .localeCompare(b.qrinit_pay_response_datetime.toString().toLowerCase()),
    render: (value) => renderStatus(value, "qrinit_pay_response_datetime"),
  },
  {
    title: "Refund Amount",
    width: 200,
    dataIndex: "refund_amount",
    align: "center",
    key: "refund_amount",
    sorter: (a: any, b: any) =>
      a.refund_amount
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_amount.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Merchant ID",
    width: 200,
    dataIndex: "refund_merchant_id",
    align: "center",
    key: "refund_merchant_id",
    sorter: (a: any, b: any) =>
      a.refund_merchant_id
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_merchant_id.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Message",
    width: 200,
    dataIndex: "refund_message",
    align: "center",
    key: "refund_message",
    sorter: (a: any, b: any) =>
      a.refund_message
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_message.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Mobile Number",
    width: 200,
    dataIndex: "refund_mobile_number",
    align: "center",
    key: "refund_mobile_number",
    sorter: (a: any, b: any) =>
      a.refund_mobile_number
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_mobile_number.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Pay Response Code",
    width: 200,
    dataIndex: "refund_pay_response_code",
    align: "center",
    key: "refund_pay_response_code",
    sorter: (a: any, b: any) =>
      a.refund_pay_response_code
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_pay_response_code.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Provider Reference Id",
    width: 200,
    dataIndex: "refund_provider_reference_id",
    align: "center",
    key: "refund_provider_reference_id",
    sorter: (a: any, b: any) =>
      a.refund_provider_reference_id
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_provider_reference_id.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Status",
    width: 200,
    dataIndex: "refund_status",
    align: "center",
    key: "refund_status",
    sorter: (a: any, b: any) =>
      a.refund_status
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_status.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Success",
    width: 200,
    dataIndex: "refund_success",
    align: "center",
    key: "refund_success",
    sorter: (a: any, b: any) =>
      a.refund_success
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_success.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Refund Transaction ID",
    width: 200,
    dataIndex: "refund_transaction_id",
    align: "center",
    key: "refund_transaction_id",
    sorter: (a: any, b: any) =>
      a.refund_transaction_id
        .toString()
        .toLowerCase()
        .localeCompare(b.refund_transaction_id.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Sub Merchant",
    width: 200,
    dataIndex: "sub_merchant",
    align: "center",
    key: "sub_merchant",
    sorter: (a: any, b: any) =>
      a.sub_merchant
        .toString()
        .toLowerCase()
        .localeCompare(b.sub_merchant.toString().toLowerCase()),
    render: renderStatus,
  },
  {
    title: "Update DateTime",
    width: 200,
    dataIndex: "update_datetime",
    align: "center",
    key: "update_datetime",
    sorter: (a: any, b: any) =>
      a.update_datetime
        .toString()
        .toLowerCase()
        .localeCompare(b.update_datetime.toString().toLowerCase()),
    render: (value) => renderStatus(value, "update_datetime"),
  },
  {
    title: "Updated At",
    width: 150,
    dataIndex: "updated_at",
    align: "center",
    key: "updated_at",
    sorter: (a: any, b: any) =>
      a.updated_at
        .toString()
        .toLowerCase()
        .localeCompare(b.updated_at.toString().toLowerCase()),
    render: (value) => renderStatus(value, "updated_at"),
  },
];
