import React, { useEffect, useState } from "react";
import { Tag, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
// import { EditOutlined } from "@ant-design/icons";
// import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
// import { useNavigate } from "react-router-dom";
// import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import { companyState } from "../../../lib/stores/companyId";
// import { companyState } from "../../../lib/stores/companyId";
// import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  console.log(record, "record");
  if (key === "isActive") {
    const color = record.isActive === 1 ? "green" : "volcano";
    return (
      <div>
        <Tag color={color}>{record.isActive === 1 ? "Active" : "Inactive"}</Tag>
      </div>
    );
  }
  if (key === "locationId") {
    if (value !== null) {
      return <CopyClipBoard text={value} />;
    } else {
      return "NA";
    }
  }
  if (key === "expiryDate") {
    if (value !== null && isISO8601Date(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
  }

  // Handling other truthy values with ellipsis and tooltip
  if (value) {
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const CompanyTypes: React.FC = () => {
  // const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "CompanyTypes", href: "/company-types" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const response = await get(apiEndpoints.getAllCompanyTypes);
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  // const handleEdit = (record: any) => {
  //   navigate(`/users/editUsers/${record.userId}`);
  //   console.log(record, "record");
  // };

  // const handleDeleteConfirmation = async (userId: string) => {
  //   try {
  //     if (userId) {
  //       const response = await del(`${apiEndpoints.deleteSKUMaster}`, {
  //         companyId: localStorage.getItem("companyId") || companyId,
  //         userId: userId,
  //       });
  //       if (response.status === 200) {
  //         notification.success({
  //           message: "Successfully Deleted",
  //         });
  //         await onSubmit();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // };

  // const handleDelete = (record: any) => {
  //   DiscardChangeModal(
  //     "Click OK to Confirm to Delete.",
  //     async () => {
  //       handleDeleteConfirmation(record?.userId);
  //     },
  //     () => {},
  //     { title: "Are you sure you want to Delete ?" }
  //   );
  // };

  const columns: ColumnsType<DataType> = [
    {
      title: "Type",
      dataIndex: "companyType",
      key: "companyType",
      sorter: (a: any, b: any) =>
        a.companyType
          .toString()
          .toLowerCase()
          .localeCompare(b.companyType.toString().toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "planName"),
    },
    {
      title: "Type Id",
      dataIndex: "companyTypeId",
      key: "companyTypeId",
      sorter: (a: any, b: any) =>
        a.companyTypeId
          .toString()
          .toLowerCase()
          .localeCompare(b.companyTypeId.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Type Order",
      dataIndex: "companyTypeOrder",
      key: "companyTypeOrder",
      sorter: (a: any, b: any) =>
        a.companyTypeOrder
          .toString()
          .toLowerCase()
          .localeCompare(b.companyTypeOrder.toString().toLowerCase()),
      width: 100,
      render: renderStatus,
    },
  ];

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={false}
        showDropDowns={false}
        pageName="Company-Types"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default CompanyTypes;
