import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, ModalProps, Typography } from "antd";

const { Text } = Typography;

interface IModalProps extends ModalProps {
  okText?: string;
  cancelText?: string;
  title?: string;
}

export const DiscardChangeModal = (
  content: string,
  onOk: () => Promise<void>,
  onCancel = () => {},
  modalProps: IModalProps = {}
) => {
  return Modal.confirm({
    title: modalProps.title,
    content: content,
    icon: <ExclamationCircleOutlined />,
    cancelText: <Text>Cancel</Text>,
    okText: <Text>Ok</Text>,
    maskClosable: true,
    closable: modalProps.closable,
    okButtonProps: modalProps.okButtonProps,
    onOk,
    onCancel,
  });
};

export default DiscardChangeModal;
