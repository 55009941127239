import React, { useEffect } from "react";
import { Breadcrumb } from "antd";

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface DynamicBreadcrumbProps {
  items: BreadcrumbItem[];
}

const DynamicBreadcrumb: React.FC<DynamicBreadcrumbProps> = ({ items }) => {
  useEffect(() => {
    console.log("Breadcrumb items updated:", items);
  }, [items]);

  return (
    <Breadcrumb style={{ margin: "16px 16px" }}>
      {items.map((item, index) => (
        <Breadcrumb.Item key={item.label + index} href={item.href}>
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default DynamicBreadcrumb;
