import { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { useRecoilState } from "recoil";
import { companyState } from "../../../lib/stores/companyId";
import getApiEndpoints from "../../../services/endpoints";
import { post } from "../../../services/apiService";
import dayjs from "dayjs";
import { Card, Empty } from "antd";

const OrderGlassesSummary = () => {
  const [companyId] = useRecoilState(companyState);
  const [data, setData] = useState<any>([]);

  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };
    const fetchData = async () => {
      try {
        const response = await post(
          apiEndpoints.getOrdersGlassesReportByCompanyId,
          payLoad
        );
        if (response.status === 200) {
          setData(response?.data);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4>
          Order and Glass for 7 Days Cumulative total of all the locations under
          this Org
        </h4>
        {/* <h3>Orders Glasses Report (7 days)</h3> */}
        {data?.length > 0 ? (
          <BarChart
            width={600}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="orderDate" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              name="Order Count"
              dataKey="orderCount"
              fill="#4287f5"
              activeBar={<Rectangle fill="#4287f5" stroke="blue" />}
            />
            <Bar
              name="Glasses Count"
              dataKey="glassesCount"
              fill="#f03e53"
              activeBar={<Rectangle fill="#f03e53" stroke="blue" />}
            />
            {/* <Bar
            name="Amount"
            dataKey="amount"
            fill="#faad07"
            activeBar={<Rectangle fill="#faad07" stroke="blue" />}
          /> */}
          </BarChart>
        ) : (
          <div
            style={{
              margin: "50%",
              width: "200px",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default OrderGlassesSummary;
